import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { APP_VERSION, BACKENDV2_URL, BACKEND_URL } from '../configs/api';
import { AngularFireStorage } from '@angular/fire/storage';
import { BehaviorSubject, lastValueFrom } from 'rxjs';
import { ShelfType, IShelfData } from 'src/app/objects/shelf';
import { IGraphOption, IMockOption, DynamicGraphInput, BuildingName, GraphDateTimeConfig } from '../objects/config';
import { LoadingService } from './loading.service';
import { Loading } from '../enum/loading';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { Platform } from '@ionic/angular';
import DEFAULT_DISPLAY_LANGUAGE from '../helpers/DisplayLanguageHelper';
import { DataService } from '../data.service';


export const TIME_LIST = [
  '10.00',
  '11.00',
  '12.00',
  '13.00',
  '14.00',
  '15.00',
  '16.00',
  '17.00',
  '18.00',
  '19.00',
  '20.00',
  '21.00',
  '22.00',
  '23.00',
];

const MAX_RETRY_FETCH = 15;

@Injectable({
  providedIn: 'root',
})
export class ConfigDataService {
  private lastFetchConfigTime = 0;
  private isFetching = false;

  fetchAppConfigErrorMessage: string;
  SPECTIFIC_UID: string[];
  TIME_LIST = TIME_LIST;
  VEHICLE_TIME_LIST = TIME_LIST;
  BIN_TIMESPENT_LIST: string[];
  BIN_TIMESPENT_BY_PIN_LIST: string[];
  PROFESSION_CLASS: string[];
  GENDER_CLASS: string[];
  AGE_CLASS: string[];
  ETHNICITY_CLASS: string[];
  SEGREGATION_CLASS: string[];
  MESSENGER_CLASS: string[];
  MESSENGER_GROUP_CLASS_LIST: string[];
  BUILDING_NAME_LIST: string[];
  FLOOR_NAME_LIST: string[];
  ZONE_NAME_LIST: string[];
  PROFILE_BY_AREA_LIST: string[];
  BAG_LIST: string[];
  SHOPPING_BAG_COLOR_LIST: string[];
  ORGANIZATION_LIST: string[];
  ORGANIZATION_NAME_LIST: string[];
  ORGANIZATION_LOGO_LIST: any = {};
  FLOOR_OBJECTS: { [buildingName: string]: { [floorName: string]: { gates?: string[]; zones?: string[]; parking?: string[] } } };
  AREA_PIN_OBJECTS: { [pinArea: string]: { 'counting_line': string[] } };
  AREA_PIN_LIST: string[];
  AREA_CAMERA_LIST: string[];
  HUMAN_PATH: { [guardId: string]: Array<{ x?: number; y?: number; z?: number }> };
  /**
   * @deprecated - mark for remove
   */
  FLOOR_OBJECT_LIST: { name: string; gate_point_list: { name: string; type: 'gate' | 'shop' | 'restaurant' }[] }[];
  LOGO_IMAGE_PATH: string;
  HEADCOUNT_CRITERION: any;
  MASK_CLASS: string[] = ['Face Mask', 'No Mask'];
  STAFF_CLASS: string[];
  CLEANER_CLASS: string[] = ['cleaner-mbk'];
  BRAND_LIST: string[];

  NAVIGATION_MENU: { title?: string; url?: string; icon?: string; type?: 'group' | 'normal'; state?: 'open' | 'collapse'; child?: { title: string; url: string }[] }[];

  TIME_DIFF_FROM_UTC = 7;

  MAIN_BUILDING: BuildingName;
  STORE_NAME: string;
  STORE_AREA: { [store: string]: number }
  STORE_IMAGE: { [store: string]: string }
  storeImageURL: { [store: string]: string }

  MESSENGER_GROUP_CLASS: { [groupName: string]: string[] } = {};
  mallTrafficLineChartDataSelector: {};
  mallAvgTrafficLineChartDataSelector: {};
  mallTrafficHourlyAvgWeekDataSelector: {};
  mallTrafficHourlyWeekDataSelector: {};
  mallTrafficHourlyDayByDaySelector: {};
  mallTrafficHourChartDataSelector: {};
  mallTrafficAllPinChartDataSelector: {};
  popularTimeLineChartDataSelector: {};
  avgPopularTimeLineChartDataSelector: {};
  GRAPH_LIST = {
    OVERALL_HEADER: [] as (DynamicGraphInput | string)[],
    OVERALL_HIGHLIGHT: [] as (DynamicGraphInput | string)[],
    FLOOR_HIGHLIGHT: [] as (DynamicGraphInput | string)[],
    ZONE_HIGHLIGHT: [] as (DynamicGraphInput | string)[],
    GOAL: [] as (DynamicGraphInput | string)[],
    VISITOR_PROFILE: [] as (DynamicGraphInput | string)[],
    TRAFFIC: {
      OVERALL: {} as { [floorName: string]: (DynamicGraphInput | string)[]; ALWAYS: (DynamicGraphInput | string)[] }, // traffic detail
      INTERACTED: [] as (DynamicGraphInput | string)[], // App gate or store summary
      INTERACTED_STORE: [] as (DynamicGraphInput | string)[],
      INTERACTED_ADS: [] as (DynamicGraphInput | string)[],
      INTERACTED_CAMPAIGN: [] as (DynamicGraphInput | string)[],
      INTERACTED_CAMPAIGN_REACH: [] as (DynamicGraphInput | string)[],
      INTERACTED_PACKAGE: [] as (DynamicGraphInput | string)[],
      INTERACTED_PACKAGE_REACH: [] as (DynamicGraphInput | string)[],
      INTERACTED_PARKING: [] as (DynamicGraphInput | string)[],
      INTERACTED_TOILET: [] as (DynamicGraphInput | string)[],
      INTERACTED_TOILET_OVER_ALL: [] as (DynamicGraphInput | string)[],
      INTERACTED_SECURITY: [] as (DynamicGraphInput | string)[]
    },
    STORE_OVERALL: {
      STORE_HIGHLIGHT_ROW_1: [] as (DynamicGraphInput | string)[],
      STORE_HIGHLIGHT_ROW_2: [] as (DynamicGraphInput | string)[],
      STORE_HIGHLIGHT_ROW_3: [] as (DynamicGraphInput | string)[],
      CUSTOMER_INSIGHTS_ROW_1: [] as (DynamicGraphInput | string)[],
      CUSTOMER_INSIGHTS_ROW_2: [] as (DynamicGraphInput | string)[],
      CUSTOMER_INSIGHTS_ROW_3: [] as (DynamicGraphInput | string)[],
      STAFF_PROD_ROW_1: [] as (DynamicGraphInput | string)[],
      STAFF_PROD_ROW_2: [] as (DynamicGraphInput | string)[],
      STAFF_PROD_ROW_3: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_CARD_ROW_1: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_CARD_ROW_2: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_CARD_ROW_3: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_ROW_1: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_ROW_2: [] as (DynamicGraphInput | string)[],
      STORE_TRAFFIC_ROW_3: [] as (DynamicGraphInput | string)[],
    },
    SCORECARD_REPORT: [] as (DynamicGraphInput | string)[],
  };

  FLOOR_LINE_CHART_COLOR_LIST = {} as { [floorName: string]: string };
  ZONE_LINE_CHART_COLOR_LIST = {} as { [zoneName: string]: string };
  FLOOR_PIE_CHART_COLOR_LIST = {} as { [floorName: string]: string };
  BUILDING_PIE_CHART_COLOR_LIST = {} as { [floorName: string]: string };
  HOVER_BG_COLOR_LIST = {} as { [graphName: string]: { [className: string]: string } };
  BG_COLOR_LIST = {} as { [graphName: string]: { [className: string]: string } };
  BILLBOARD_PACKAGE_COLORS = {} as { [packageName: string]: { color: string } };

  GRAPH_CONFIG: IGraphOption = {
    ENTRANCE_EXIT_ANALYSIS: {
      FLOOR: 'GROUND'
    },
    CURRENT_CUSTOMER: {
      ALL: { min: 0, max: 15000, },
      GROUND: { min: 0, max: 15000, },
    },
    PARKING_LOT_OCCUPANCY: { min: 0, max: 1500 },
    INTERACTABLE_LINE_CHART: {},
    INITIAL_SELECTOR: {},
  };

  TARGET_CONFIG: any = {
    headcount: { target: 0, max: 100 },
    avgMinSpent: { target: 0, max: 100 },
    netVisitorHour: { target: 0, max: 0 },
  };

  MOCK_CONFIG: IMockOption = {
    PARKING_LOT_OCCUPANCY: {
      default: {
        min: 0,
        max: 1500,
      },
      mbk_parking_2a: {
        current: 91,
        min: 0,
        max: 100,
      },
      mbk_parking_2b: {
        current: 84,
        min: 0,
        max: 100,
      },
      mbk_parking: {
        current: 721,
        min: 0,
        max: 3000,
      }
    },
    FLOOR_TRAFFIC: {
      GROUND: {
        min: 1100,
        max: 15000
      }
    },
  };

  EVENT_CONFIG = {
    canSelectMultipleLocation: false,
    locations: ['Zone A', 'Zone B', 'Zone A-B'],
    campainTypes: ['Store Wide Promotion', 'Category Promotion', 'Event']
  } as {
    canSelectMultipleLocation: boolean;
    locations: string[];
    campainTypes: string[];
  };
  ETHNICITY_CLASS_CROSS_PROFILE: [
    'young_adults',
    'teenagers',
    'white'
  ];
  popupMessageData: string;
  genderImageSrc: any = {};
  cleanerImageSrc: any = {};
  ethnicityImageSrc: any = {};
  ageImageSrc: any = {};
  logoImagedListSrc: any = {};
  entranceExitGateImageSrc: { [floorName: string]: { [gateName: string]: { entrance: string; exit: string } } } = {};
  entranceExitPinImageSrc: { [pinName: string]: { entrance: string; exit: string } } = {};
  packageImageSrc: { [packageName: string]: string } = {};
  /**
   * @deprecated use entranceExitGateImageSrc instead
   */
  entranceGateImageSrc: any = {};
  /**
   * @deprecated use entranceExitGateImageSrc instead
   */
  exitGateImageSrc: any = {};
  floorMapImageSrc: any = {};
  bagImageSrc: any = {};
  logoImageSrc: string;
  maskImageSrc: any = {};
  staffImageSrc: any = {};
  mockAreaVideoSrc: any = {};
  countingLineImageSrc: any = {};
  messengerImageSrc: Record<string, any[]> = {};
  brandImageSrc: Record<string, any> = {};
  messengerLogoImageSrc: Record<string, any> = {};
  shoppingBagColorImageSrc: Record<string, string[]> = {};

  isConfigReady$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dependencyState: { [dependency in GraphDependency]?: 'REAL' | 'MOCK' | 'MOCK_COND' } = {};

  isEntranceDataMode$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  FEATURE_FLAGS: any = {};

  isOverallDataWarning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isEventDataWarning$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  dataWarningText = {
    overall_data: '',
    event_data: '',
  };
  isOverallDataWarning = false;
  isEventDataWarning = false;
  showUpdateAlert = false;
  forceCheckUpdate = false;

  isShowInAppAlert = false;
  inAppAlertText = {
    title: '',
    body: '',
    type: null,
  };

  isHaveGoal$ = new BehaviorSubject<boolean>(true);
  currentOrganization$ = new BehaviorSubject<string>(null);
  selectedOrganization$: BehaviorSubject<string> = new BehaviorSubject(null);

  CONFIG_3D_MAP: any = {
    camera: {
      type: 'OrthographicCamera',
      tx: 0.0,
      ty: 0.0,
      tz: 0.0,
      x: 120,
      y: 11,
      z: 25,
      minZoom: 2,
      maxZoom: 24,
      minDistance: 400,
      maxDistance: 4000,
      defaultZoom: 5
    },
    textureSize: 256,
    annotation_scale: 4,
    annotation_font: 'bold 196px sans-serif',
    humanBotWalkSpeed: 0.3,
    annotation_bg_color: {
      billboard: '#dbd7b4',
      default: '#dbd7b4'
    },
    annotation_select_bg_color: {
      default: '#dbd7b4',
    },
    annotation_store_text_color: '#ffffff',
    floor_material_color: '#ffffff',
    heatmapRadius: 150,
    demo: {
      /** in ms */
      humanTimeout: 5000,
      /** in seconds */
      afkDuration: 300,
      minGateStoreDuration: 10,
      maxGateStoreDuration: 30,
      /** range 0..1 (store is derrive from 1.0 - gate - floor) */
      changeProb: {
        gate: 0.3,
        floor: 0.1,
      },
      human_walking: {
        text_color: 'black',
        text_size: '12px',
      },
    },
  };

  CONFIG_3D_MAPS_BY_ORGS = { ICONSIAM: {}, ICS: {}, ONESIAM: {} };

  ORGANIZATION_POSITION = [
    'Admin',
    'Management',
    'IT & Engineer',
    'Marketing',
    'Sales',
    'Security',
    'Others',
    'Staff',
    'Staff Admin',
  ];

  APP_TYPE = 'MALL';
  MAIN_PAGE = 'home';

  SHELFS: IShelfData[] = [{
    id: 'shelf_0',
    name: 'shelf_22',
    type: ShelfType.OneSide,
    transFormationMatrix: [2.220446049250313e-13, 0, -1000, 0, 0, 1000, 0, 0, 1000, 0, 2.220446049250313e-13, 0, 12560, 150, 2580, 1],
  }, {
    id: 'shelf_1',
    name: 'shelf_1',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1000, 0, -1.2246467991473532e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 3000, 150, 200, 1],
  }, {
    id: 'shelf_2',
    name: 'shelf_2',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1000, 0, -1.2246467991473532e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 4050, 150, 200, 1],
  }, {
    id: 'shelf_3',
    name: 'shelf_3',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1000, 0, -1.2246467991473532e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 5100, 150, 200, 1],
  }, {
    id: 'shelf_4',
    name: 'shelf_4',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1545, 0, -1.8920793046826608e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 7550, 150, 200, 1],
  }, {
    id: 'shelf_5',
    name: 'shelf_5',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1545, 0, -1.8920793046826608e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 10000, 150, 200, 1],
  }, {
    id: 'shelf_6',
    name: 'shelf_6',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1000, 0, -1.2246467991473532e-13, 0, 0, 1000, 0, 0, 1.2246467991473532e-13, 0, -1000, 0, 11500, 150, 200, 1],
  }, {
    id: 'shelf_7',
    name: 'shelf_7',
    type: ShelfType.OneSide,
    transFormationMatrix: [-2.220446049250313e-13, 0, 1000, 0, 0, 1000, 0, 0, -1000, 0, -2.220446049250313e-13, 0, 1600, 150, 900, 1],
  }, {
    id: 'shelf_8',
    name: 'shelf_8',
    type: ShelfType.OmniSide,
    transFormationMatrix: [800, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 800, 0, 3720, 150, 1710, 1],
  }, {
    id: 'shelf_9',
    name: 'shelf_9',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 500, 0, 0, 0, 0, 700, 0, 8200, 150, 1650, 1],
  }, {
    id: 'shelf_10',
    name: 'shelf_10',
    type: ShelfType.OneSide,
    transFormationMatrix: [-1000, 0, -1.2246467991473532e-13, 0, 0, 500, 0, 0, 8.572527594031473e-14, 0, -700, 0, 8200, 150, 1890, 1],
  }, {
    id: 'shelf_11',
    name: 'shelf_11',
    type: ShelfType.OmniSide,
    transFormationMatrix: [800, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 800, 0, 10800, 150, 1530, 1],
  }, {
    id: 'shelf_12',
    name: 'shelf_12',
    type: ShelfType.OneSide,
    transFormationMatrix: [-2.220446049250313e-13, 0, 1000, 0, 0, 1000, 0, 0, -1000, 0, -2.220446049250313e-13, 0, 1600, 150, 2750, 1],
  }, {
    id: 'shelf_13',
    name: 'shelf_13',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 2200, 150, 3750, 1],
  }, {
    id: 'shelf_14',
    name: 'shelf_14',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 3250, 150, 3750, 1],
  }, {
    id: 'shelf_15',
    name: 'shelf_15',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 4300, 150, 3750, 1],
  }, {
    id: 'shelf_16',
    name: 'shelf_16',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 5350, 150, 3750, 1],
  }, {
    id: 'shelf_17',
    name: 'shelf_17',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 6400, 150, 3750, 1],
  }, {
    id: 'shelf_18',
    name: 'shelf_18',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 7450, 150, 3500, 1],
  }, {
    id: 'shelf_19',
    name: 'shelf_19',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 8500, 150, 3750, 1],
  }, {
    id: 'shelf_20',
    name: 'shelf_20',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 9550, 150, 3750, 1],
  }, {
    id: 'shelf_21',
    name: 'shelf_21',
    type: ShelfType.OneSide,
    transFormationMatrix: [1000, 0, 0, 0, 0, 1000, 0, 0, 0, 0, 1000, 0, 10600, 150, 3750, 1],
  }];

  DISPLAY_LANGUAGE: typeof DEFAULT_DISPLAY_LANGUAGE & { [NAME: string]: any } = DEFAULT_DISPLAY_LANGUAGE;

  GRAPH_DATETIME_CONFIG: GraphDateTimeConfig = {};

  VEHICLE_PROFILE_CONFIG: { [profileName: string]: string[] } = {};

  VEHICLE_PARKING_LIST: string[] = [];

  constructor(
    private http: HttpClient,
    private authenticationService: AuthenticationService,
    private storage: AngularFireStorage,
    private loadingService: LoadingService,
    private platform: Platform,
    private dataService: DataService,
  ) { }


  async loadMockAreaVideos(area_list: Array<string>, organization: string) {
    for (const mock_area of area_list) {
      this.getImageURL(`${organization.toLocaleLowerCase()}-mock-video/${mock_area}/video.mp4`)
        .then(image_url => {
          this.mockAreaVideoSrc[mock_area] = image_url;
        })
        .catch(err => {
          // console.error(err);
          this.mockAreaVideoSrc[mock_area] = null;
        });
    }
  }

  async loadExampleImages() {
    if (this.isFeatureEnabled('images', 'staff_source')) {
      this.STAFF_CLASS.forEach(staff => {
        this.staffImageSrc[staff] = [];
        for (let index = 1; index <= 3; index++) {
          this.getImageURL(`${this.isFeatureEnabled('images', 'staff_source')}-customer-segment-img/staff/${staff}/${index}.jpg`)
            .then(image_url => {
              this.staffImageSrc[staff].push(image_url);
            })
            .catch(err => {
              console.log(err);
              this.staffImageSrc[staff].push('../assets/image/placeholder.png');
            });
        }
      });
    }

    if (this.isFeatureEnabled('images', 'cleaner_source')) {
      this.CLEANER_CLASS.forEach(cleaner => {
        this.cleanerImageSrc[cleaner] = [];
        for (let index = 0; index <= 2; index++) {
          this.getImageURL(`${this.isFeatureEnabled('images', 'cleaner_source')}-customer-segment-img/cleaner/${index}.jpg`)
            .then(image_url => {
              this.cleanerImageSrc[cleaner].push(image_url);
            })
            .catch(err => {
              console.log(err);
              this.cleanerImageSrc[cleaner].push('../assets/image/placeholder.png');
            });
        }
      });
    }

    this.MASK_CLASS.forEach(mask => {
      // if (this.maskImageSrc[mask] === undefined) {
      //   this.maskImageSrc[mask] = [];
      // }
      this.maskImageSrc[mask] = [];
      for (let index = 1; index <= 6; index++) {
        this.getImageURL(`customer-segment-img/mask/${mask}/${index}.jpg`)
          .then(image_url => {
            this.maskImageSrc[mask].push(image_url);
          })
          .catch(err => {
            console.log(err);
            this.maskImageSrc[mask].push('../assets/image/placeholder.png');
          });
        // this.maskImageSrc[mask].push(`assets/image/customer-segment/mask/${mask}/${index}.jpg`);
      }
    });

    this.GENDER_CLASS.forEach(gender => {
      const genderKey = this.DISPLAY_LANGUAGE.GENDER_CLASS[gender];
      this.ethnicityImageSrc[genderKey] = {};
      this.ageImageSrc[genderKey] = {};
      this.genderImageSrc[genderKey] = [];
      // if (this.genderImageSrc[genderKey] === undefined) {
      //   this.genderImageSrc[genderKey] = [];
      // }
      for (let index = 1; index <= 6; index++) {
        this.getImageURL(`customer-segment-img/gender/${genderKey}/${index}.png`)
          .then(image_url => {
            this.genderImageSrc[genderKey].push(image_url);
          })
          .catch(err => {
            console.log(err);
            this.genderImageSrc[genderKey].push('../assets/image/placeholder.png');
          });
        // this.genderImageSrc[genderKey].push(`assets/image/customer-segment/gender/${genderKey}/${index}.png`);
      }

      this.ETHNICITY_CLASS.forEach(ethnicity => {
        this.ethnicityImageSrc[genderKey][ethnicity] = [];
        // if (this.ethnicityImageSrc[genderKey] === undefined) {
        //   this.ethnicityImageSrc[genderKey] = {};
        // }
        // if (this.ethnicityImageSrc[genderKey][ethnicity] === undefined) {
        //   this.ethnicityImageSrc[genderKey][ethnicity] = [];
        // }
        for (let index = 1; index <= 6; index++) {
          this.getImageURL(`customer-segment-img/ethnicity/${ethnicity}/${genderKey}_${index}.jpg`)
            .then(image_url => {
              this.ethnicityImageSrc[genderKey][ethnicity].push(image_url);
            })
            .catch(err => {
              console.log(err);
              this.ethnicityImageSrc[genderKey][ethnicity].push('../assets/image/placeholder.png');
            });
          // this.ethnicityImageSrc[genderKey][ethnicity].push(`assets/image/customer-segment/ethnicity/${ethnicity}/${genderKey}_${index}.jpg`);
        }
      });

      this.AGE_CLASS.forEach(age => {
        const ageKey = this.DISPLAY_LANGUAGE.AGE_CLASS[age];
        this.ageImageSrc[genderKey][ageKey] = [];
        // if (this.ageImageSrc[genderKey] === undefined) {
        //   this.ageImageSrc[genderKey] = {};
        // }
        // if (this.ageImageSrc[genderKey][ageKey] === undefined) {
        //   this.ageImageSrc[genderKey][ageKey] = [];
        // }
        for (let index = 1; index <= 6; index++) {
          this.getImageURL(`customer-segment-img/age/${ageKey}/${genderKey}_${index}.jpg`)
            .then(image_url => {
              this.ageImageSrc[genderKey][ageKey].push(image_url);
            })
            .catch(err => {
              console.log(err);
              this.ageImageSrc[genderKey][ageKey].push('../assets/image/placeholder.png');
            });
          // this.ageImageSrc[genderKey][ageKey].push(`assets/image/customer-segment/age/${ageKey}/${genderKey}_${index}.jpg`);
        }
      });
    });

    this.BAG_LIST.forEach(bag_type => {
      if (this.bagImageSrc[bag_type] === undefined) {
        this.bagImageSrc[bag_type] = [];
      }
      for (let index = 1; index <= 6; index++) {
        this.bagImageSrc[bag_type].push(`assets/image/bag/${bag_type}/${index}.png`);
      }
    });

    this.MESSENGER_CLASS.forEach(messenger => {
      const parseName = messenger;
      this.messengerImageSrc[parseName] = [];
      this.messengerLogoImageSrc[parseName] = [];
      this.getImageURL(`customer-segment-img/messenger/logo/${messenger}.png`)
        .then(image_url => {
          this.messengerLogoImageSrc[parseName] = image_url;
        })
        .catch(err => {
          console.log(err);
          this.messengerLogoImageSrc[parseName] = '../assets/image/placeholder.png';
        });
      for (let index = 0; index < 2; index++) {
        this.getImageURL(`customer-segment-img/messenger/clothes/${parseName}/${messenger}_img${index + 1}.jpg`)
          .then(image_url => {
            this.messengerImageSrc[parseName].push(image_url);
          })
          .catch(err => {
            console.log(err);
            this.messengerImageSrc[parseName].push('../assets/image/placeholder.png');
          });
      }
      // if (this.messengerImageSrc[parseName] === undefined || this.messengerLogoImageSrc[parseName] === undefined) {
      //   this.messengerImageSrc[parseName] = ['../assets/image/placeholder.png', '../assets/image/placeholder.png'];
      //   this.messengerLogoImageSrc[parseName] = '../assets/image/placeholder.png';
      // }
      // // this.messengerLogoImageSrc[parseName] = `assets/image/customer-segment/messenger/logo/${messenger}.png`;
      // for (let index = 0; index < 2; index++) {
      //   this.messengerImageSrc[parseName].push(`assets/image/customer-segment/messenger/clothes/${parseName}/${messenger}_img${index + 1}.jpg`);
      // }
    });

    if (this.BRAND_LIST) {
      this.BRAND_LIST.forEach(brand => {
        this.brandImageSrc[brand] = [];
        // TODO: update with organization
        this.getImageURL(`pav-brand-img/${brand}.jpg`)
          .then(image_url => {
            this.brandImageSrc[brand] = image_url;
          })
          .catch(err => {
            console.error(err);
            this.brandImageSrc[brand] = '../assets/image/placeholder.png';
          });
      });
    }

    this.SHOPPING_BAG_COLOR_LIST.forEach(color => {
      const parseName = this.DISPLAY_LANGUAGE.SHOPPING_BAG_COLOR_CLASS[color];
      if (this.shoppingBagColorImageSrc[parseName] === undefined) {
        this.shoppingBagColorImageSrc[parseName] = [];
      }
      for (let index = 0; index < 2; index++) {
        this.shoppingBagColorImageSrc[parseName].push(`assets/image/bag/shopping-bag-color/${parseName}/${color}-${index + 1}.jpg`);
      }
    });

  }

  async loadPinImages() {
    const organization = this.currentOrganization;
    const folderName = this.isFeatureEnabled('traffic_page', 'gate_image_flatten') ? `${organization.toLocaleLowerCase()}-gate-img` : `${organization.toLocaleLowerCase()}-pin-img`;
    await Promise.all(this.AREA_PIN_LIST.map(async pinName => {
      this.entranceExitPinImageSrc[pinName] = { entrance: undefined, exit: undefined };
      for (const channel of ['entrance', 'exit'] as ['entrance', 'exit']) {
        try {
          const imgPath = `${folderName}/${pinName}/${channel}.jpg`;
          const imageURL = await this.getImageURL(imgPath);
          this.entranceExitPinImageSrc[pinName][channel] = imageURL;
        } catch (err) {
          // console.error(err);
          this.entranceExitPinImageSrc[pinName][channel] = null;
        }
      }
    }));
  }

  async loadPackageImages() {
    const organization = this.currentOrganization;
    const packageList = Object.keys(this.DISPLAY_LANGUAGE.BILLBOARD_PACKAGE_NAME);
    await Promise.all(packageList.map(async packageName => {
      this.packageImageSrc[packageName] = null;
      try {
        const imgPath = `${organization.toLocaleLowerCase()}-package-img/${packageName}.jpg`;
        const imageURL = await this.getImageURL(imgPath);
        this.packageImageSrc[packageName] = imageURL;
      } catch (err) {
        console.error(err);
        this.packageImageSrc[packageName] = null;
      }
    }));
  }

  async loadStoreImages() {
    const organization = this.currentOrganization;
    await Promise.all(Object.entries(this.FLOOR_OBJECTS[this.MAIN_BUILDING]).map(([floor_name, floorData]) => {
      this.entranceExitGateImageSrc[floor_name] = {};
      this.entranceGateImageSrc[floor_name] = {};
      this.exitGateImageSrc[floor_name] = {};
      return Promise.all((floorData.gates || []).map(async gate_name => {
        // TODO: remove dash underscore replacement for legacy support
        if (this.isFeatureEnabled('gate_image_legacy')) {
          gate_name = gate_name.replace('-', '_');
        }
        this.entranceExitGateImageSrc[floor_name][gate_name] = { entrance: undefined, exit: undefined };
        for (const channel of ['entrance', 'exit'] as ['entrance', 'exit']) {
          try {
            const imgPath = `${organization.toLocaleLowerCase()}-gate-img/${floor_name}/${gate_name}/${channel}.jpg`;
            const imageURL = await this.getImageURL(imgPath);
            this.entranceExitGateImageSrc[floor_name][gate_name][channel] = imageURL;
            // TODO: This is legacy support code need to remove
            if (channel === 'entrance') {
              this.entranceGateImageSrc[floor_name][gate_name] = imageURL;
            } else {
              this.exitGateImageSrc[floor_name][gate_name] = imageURL;
            }
          } catch (err) {
            console.error(err);
            this.entranceExitGateImageSrc[floor_name][gate_name][channel] = null;
            if (channel === 'entrance') {
              this.entranceGateImageSrc[floor_name][gate_name] = null;
            } else {
              this.exitGateImageSrc[floor_name][gate_name] = null;
            }
          }
        }
      }));
    }));
  }

  async loadStoreOverallImages(): Promise<{ [key: string]: string }> {
    const storeImageObject: { [key: string]: string } = {};

    // Iterate over STORE_IMAGE to fetch URLs
    await Promise.all(Object.entries(this.STORE_IMAGE).map(async ([storeKey, storePath]) => {
      try {
        // Fetch the URL using getImageURL function
        const imageURL = await this.getImageURL(storePath);

        // Assign fetched URL to the storeKey in the object
        storeImageObject[storeKey] = imageURL;
      } catch (error) {
        console.error(`Failed to fetch image for ${storeKey}:`, error);
        // Assign null if fetching fails
        storeImageObject[storeKey] = null;
      }
    }));

    // Return the final object
    return storeImageObject;
  }

  async loadLogoImage() {
    const imgList = {};
    this.getImageURL(this.LOGO_IMAGE_PATH)
      .then(image_url => {
        this.logoImageSrc = image_url;
      })
      .catch(err => {
        console.error(err);
        this.logoImageSrc = null;
      });
    if (this.ORGANIZATION_LOGO_LIST) {
      Object.keys(this.ORGANIZATION_LOGO_LIST).forEach(key => {
        this.getImageURL(this.ORGANIZATION_LOGO_LIST[key])
          .then(image_url => {
            if (this.authenticationService.USER_ROLES?.roles?.can_view_org_data) {
              if (this.authenticationService.USER_ROLES?.roles?.can_view_org_data.includes(key)) {
                imgList[key] = image_url;
              }
            } else {
              imgList[key] = image_url;
            }
          })
          .catch(err => {
            console.error(err);
          });
      });
      this.logoImagedListSrc = imgList;
    }
  }

  async loadDataImages(sourceList: string[], pathName: string, dataImageURL: { [keyName: string]: string }) {
    await Promise.all(sourceList.map(async sourceName => {
      dataImageURL[sourceName] = null;
      try {
        const imgPath = `${pathName.toLocaleLowerCase()}/${sourceName}.jpg`;
        const imageURL = await this.getImageURL(imgPath);
        dataImageURL[sourceName] = imageURL;
      } catch (err) {
        dataImageURL[sourceName] = null;
      }
    }));
  }

  async loadDataImageFromObjects(sourceObjects: { [areaName: string]: { [attrName: string]: string[] } }, pathName: string) {
    const dataImageURL: { [keyName: string]: string } = {};
    await Promise.all(Object.entries(sourceObjects).map(([areaName, areaData]) => {
      (areaData?.counting_line || []).map(async counting_line_name => {
        try {
          const imgPath = `${pathName.toLocaleLowerCase()}/${areaName}/${counting_line_name}.jpg`;
          const imageURL = await this.getImageURL(imgPath);
          this.countingLineImageSrc[counting_line_name] = imageURL;
        } catch (err) {
          dataImageURL[counting_line_name] = null;
        }
      });
    }));
    return dataImageURL;
  }

  getImageURL(filepath: string) {
    if (!filepath) {
      throw new Error(`Firebase Storage Get Image URL Error \n${filepath}`);
    }
    else {
      return lastValueFrom<string>(this.storage
        .ref(filepath)
        .getDownloadURL());
    }
  }

  async loadAppConfig() {
    if ((this.isConfigReady && (Date.now() - this.lastFetchConfigTime < (1 * 60 * 1000)))) { return; } // already load;
    for (let RETRY_TIME = 0; RETRY_TIME <= MAX_RETRY_FETCH; RETRY_TIME++) {
      ;
      try {
        await this.deriveAppconfig();
        return;
      } catch (e) {
        console.log('ERROR TIMES', RETRY_TIME);
        this.fetchAppConfigErrorMessage = this.platform.is('hybrid') ? 'Your application version is out of date, please update' : 'Your application version is out of date, please refresh in a few minutes while your application is updating.';
        console.log(e);
      }
    }
    return this.authenticationService.logout(true);
  }

  async deriveAppconfig() {
    if (this.isFetching) {
      // return;
      let times = 0;
      while (!this.isConfigReady && times <= (1000 / 100 * 60)) {
        await new Promise(resolve => setTimeout(resolve, 100));
        times += 1;
      }
      if (!this.isConfigReady) {
        throw Error('unable to fetch config after 60 seconds');
      }
    }
    try {
      this.isFetching = true;
      this.loadingService.startLoading(Loading.APP_CONFIG);
      const [config_data, userConfigData] = await Promise.all([this.fetchAppConfig(), this.fetchUserSettings()]);
      const org = userConfigData.selected_org;
      const dataStatus: any = (await Promise.resolve(this.fetchDataStatus()))[0];
      const appisLatest: any = await Promise.resolve(this.fetchCheckAppisLatest());
      const inAppMsgData: any = (await Promise.resolve(this.fetchInAppmessageAlert()) || {})[0];
      if (inAppMsgData) {
        this.inAppAlertText = {
          body: inAppMsgData.message_text,
          title: inAppMsgData.message_title,
          type: inAppMsgData.message_type,
        };
        this.isShowInAppAlert = inAppMsgData.is_show;
      }
      this.showUpdateAlert = appisLatest?.show_update_alert;
      this.forceCheckUpdate = appisLatest?.force_check_update;
      this.dataWarningText.overall_data = dataStatus?.overall_data.status_text || this.dataWarningText.overall_data;
      this.dataWarningText.event_data = dataStatus?.event_data.status_text || this.dataWarningText.event_data;
      this.isOverallDataWarning = dataStatus?.overall_data.is_warning || this.isOverallDataWarning;
      this.isEventDataWarning = dataStatus?.event_data.is_warning || this.isEventDataWarning;
      this.SPECTIFIC_UID = config_data.SPECTIFIC_UID;
      this.ORGANIZATION_LIST = config_data.ORGANIZATION_LIST;
      this.ORGANIZATION_POSITION = config_data.POSITION_LIST;
      this.ORGANIZATION_LOGO_LIST = config_data.ORGANIZATION_LOGO_LIST;
      this.NAVIGATION_MENU = config_data.NAVIGATION_MENU;
      this.MAIN_PAGE = config_data.MAIN_PAGE || this.MAIN_PAGE;
      this.PROFESSION_CLASS = config_data.PROFESSION_CLASS;
      this.GENDER_CLASS = config_data.GENDER_CLASS;
      this.AGE_CLASS = config_data.AGE_CLASS;
      this.STAFF_CLASS = config_data.STAFF_CLASS;
      this.ETHNICITY_CLASS = config_data.ETHNICITY_CLASS;
      this.SEGREGATION_CLASS = config_data.SEGREGATION_CLASS;
      this.FLOOR_OBJECTS = config_data.FLOOR_OBJECTS;
      this.MESSENGER_CLASS = config_data.MESSENGER_CLASS;
      this.SHOPPING_BAG_COLOR_LIST = config_data.SHOPPING_BAG_COLOR_LIST;
      this.TIME_LIST = config_data.TIME_LIST || TIME_LIST;
      this.MESSENGER_GROUP_CLASS = config_data.MESSENGER_GROUP_CLASS;
      this.MESSENGER_GROUP_CLASS_LIST = config_data.MESSENGER_GROUP_CLASS_LIST;
      this.VEHICLE_TIME_LIST = config_data.VEHICLE_TIME_LIST || TIME_LIST;
      this.AREA_PIN_OBJECTS = config_data.AREA_PIN_OBJECTS;
      this.AREA_PIN_LIST = config_data.AREA_PIN_LIST;
      this.BIN_TIMESPENT_LIST = config_data.BIN_TIMESPENT_LIST;
      this.BIN_TIMESPENT_BY_PIN_LIST = config_data.BIN_TIMESPENT_BY_PIN_LIST;
      this.BRAND_LIST = config_data.BRAND_LIST;

      this.FLOOR_LINE_CHART_COLOR_LIST = config_data.FLOOR_LINE_CHART_COLOR_LIST;
      this.ZONE_LINE_CHART_COLOR_LIST = config_data.ZONE_LINE_CHART_COLOR_LIST;
      this.BUILDING_PIE_CHART_COLOR_LIST = config_data.BUILDING_PIE_CHART_COLOR_LIST;
      this.FLOOR_PIE_CHART_COLOR_LIST = config_data.FLOOR_PIE_CHART_COLOR_LIST;
      this.HOVER_BG_COLOR_LIST = config_data.HOVER_BG_COLOR_LIST;
      this.BG_COLOR_LIST = config_data.BG_COLOR_LIST;
      this.BILLBOARD_PACKAGE_COLORS = config_data.BILLBOARD_PACKAGE_COLORS;

      this.ORGANIZATION_NAME_LIST = config_data.ORGANIZATION_NAME_LIST;
      this.BUILDING_NAME_LIST = config_data.BUILDING_NAME_LIST;
      this.FLOOR_NAME_LIST = config_data.FLOOR_NAME_LIST;
      this.ZONE_NAME_LIST = config_data.ZONE_NAME_LIST;
      this.PROFILE_BY_AREA_LIST = config_data.PROFILE_BY_AREA_LIST;
      this.BAG_LIST = config_data.BAG_LIST;

      this.MAIN_BUILDING = config_data.MAIN_BUILDING || this.MAIN_BUILDING;
      this.GRAPH_LIST = config_data.GRAPH_LIST || this.GRAPH_LIST;
      this.dependencyState = config_data.DEPENDENCY_STATE || {};
      this.FLOOR_OBJECT_LIST = config_data.FLOOR_OBJECT_LIST || {}; // backend no longer send FLOOR_OBJECT_LIST
      this.LOGO_IMAGE_PATH = config_data.ORGANIZATION_LOGO;
      this.HEADCOUNT_CRITERION = config_data.HEADCOUNT_CRITERION;

      this.FEATURE_FLAGS = config_data.FEATURE_FLAGS;
      this.DISPLAY_LANGUAGE = config_data.DISPLAY_LANGUAGE || this.DISPLAY_LANGUAGE;
      this.GRAPH_CONFIG = config_data.GRAPH_CONFIG || this.GRAPH_CONFIG;

      this.MOCK_CONFIG = config_data.MOCK_CONFIG || this.MOCK_CONFIG;
      this.CONFIG_3D_MAP = config_data.CONFIG_3D_MAP || this.CONFIG_3D_MAP;
      this.CONFIG_3D_MAPS_BY_ORGS = config_data.CONFIG_3D_MAPS_BY_ORGS;
      this.EVENT_CONFIG = config_data.EVENT_CONFIG || this.EVENT_CONFIG;
      this.GRAPH_DATETIME_CONFIG = config_data.GRAPH_DATETIME_CONFIG;
      this.VEHICLE_PROFILE_CONFIG = config_data.VEHICLE_PROFILE_CONFIG;
      this.AREA_CAMERA_LIST = config_data.AREA_CAMERA_LIST;
      this.HUMAN_PATH = config_data.HUMAN_PATH;
      this.VEHICLE_PARKING_LIST = config_data.VEHICLE_PARKING_LIST || this.VEHICLE_PARKING_LIST;
      this.mallTrafficLineChartDataSelector = config_data.mallTrafficLineChartDataSelector || this.mallTrafficLineChartDataSelector;
      this.mallTrafficHourlyAvgWeekDataSelector = config_data.mallTrafficHourlyAvgWeekDataSelector || this.mallTrafficHourlyAvgWeekDataSelector;
      this.mallTrafficHourlyWeekDataSelector = config_data.mallTrafficHourlyWeekDataSelector || this.mallTrafficHourlyWeekDataSelector;
      this.mallTrafficHourlyDayByDaySelector = config_data.mallTrafficHourlyDayByDaySelector || this.mallTrafficHourlyDayByDaySelector;
      this.mallTrafficHourChartDataSelector = config_data.mallTrafficHourChartDataSelector || this.mallTrafficHourChartDataSelector;
      this.mallTrafficAllPinChartDataSelector = config_data.mallTrafficAllPinChartDataSelector || this.mallTrafficAllPinChartDataSelector;
      this.popularTimeLineChartDataSelector = config_data.popularTimeLineChartDataSelector || this.popularTimeLineChartDataSelector;
      this.avgPopularTimeLineChartDataSelector = config_data.avgPopularTimeLineChartDataSelector || this.avgPopularTimeLineChartDataSelector;

      this.APP_TYPE = config_data.APP_TYPE || this.APP_TYPE;
      if (this.APP_TYPE === 'STORE') {
        this.STORE_NAME = config_data.STORE_NAME;
        this.STORE_AREA = config_data.STORE_AREA;
        this.STORE_IMAGE = config_data.STORE_IMAGE;
        this.storeImageURL = await this.loadStoreOverallImages();
      }

      this.TARGET_CONFIG = config_data.TARGET_CONFIG || this.TARGET_CONFIG;

      await this.loadLogoImage();
      this.lastFetchConfigTime = Date.now();
      this.isConfigReady$.next(true);
      this.initNavigationMenu();
      this.isOverallDataWarning$.next(this.isOverallDataWarning);
      this.isEventDataWarning$.next(this.isEventDataWarning);
      this.loadingService.stopLoading(Loading.APP_CONFIG);
      this.isFetching = false;
      if (this.isFeatureEnabled('traffic_setting', 'model_3d_selector')) {
        this.selectedOrganization$.next(this.convertBuildingNameToOrganizationName(this.MAIN_BUILDING));
      }
      this.currentOrganization$.next(org);
      if (this.AREA_PIN_LIST) {
        this.loadPinImages();
      }
      if (!this.isFeatureEnabled('traffic_page', 'multiple_view')) {
        await this.loadExampleImages();
      }
      if (this.isFeatureEnabled('traffic_page', 'store_image')) {
        this.loadStoreImages();
      }
      if (this.isFeatureEnabled('traffic_page', 'mock_area_video')) {
        await this.loadMockAreaVideos(this.AREA_CAMERA_LIST, org);
      }
      if (this.isFeatureEnabled('traffic_page', 'multiple_view')) {
        await this.loadPackageImages();
        const countingLinePathName = 'planb-counting-line-img';
        this.countingLineImageSrc = await this.loadDataImageFromObjects(this.AREA_PIN_OBJECTS, countingLinePathName);
      }
      return Promise.resolve();
    } catch (err) {
      // this.showConfigFetchingError();
      // this.fetchAppConfigErrorMessage = this.platform.is('hybrid') ? 'Your application version is out of date, please update' : 'Your application version is out of date, please refresh in a few minutes while your application is updating.';
      // console.log('Your application version is out of date, please update\n', err);
      throw Error(err);
    } finally {
      this.isFetching = false;
    }
  }

  async fetchAppConfig() {
    const requestURL = `/retail_customer_api_v2/api/v2/system/frontend-configuration?version=${APP_VERSION}`;
    return this.dataService.fetchData(requestURL);
  }

  get isEntranceDataMode() {
    return this.isEntranceDataMode$.value;
  }

  toggleDataMode(isEntranceDataMode: boolean) {
    this.isEntranceDataMode$.next(isEntranceDataMode);
  }

  isFeatureEnabled(feature, feature2 = null) {
    if (this.FEATURE_FLAGS) {
      if (feature2 !== null) {
        if (this.FEATURE_FLAGS[feature]) {
          return this.FEATURE_FLAGS[feature][feature2];
        }
      } else {
        return this.FEATURE_FLAGS[feature];
      }
    }
    return false;
  }

  initNavigationMenu() {
    if (this.APP_TYPE === 'STORE') {
      this.NAVIGATION_MENU = [
        { title: 'Highlights', url: '/home', icon: 'home' },
        { title: 'Visitor Profile', url: '/customer', icon: 'person' },
        { title: 'Store Traffic Analysis', url: '/store-traffic', icon: 'map' },
        { title: 'SKU Performance', url: '/sku', icon: 'calendar' }
      ];
    }
  }

  isFeatureLock(feature, feature2 = null): boolean {
    if (this.FEATURE_FLAGS) {
      if (feature2) {
        if (this.FEATURE_FLAGS[feature]) {
          return this.FEATURE_FLAGS[feature][feature2] === 'LOCK';
        }
      } else {
        return this.FEATURE_FLAGS[feature] === 'LOCK';
      }
    }
    return false;
  }

  convertOrganizationNameToBuildingName(orgName: string): string | null {
    if (!orgName) { return null; }
    switch (orgName) {
      case 'ICONSIAM':
        return 'icon_siam';
      case 'ICS':
        return 'ics';
      case 'ONESIAM':
        return 'onesiam';
      default:
        return null;
    }
  }

  convertBuildingNameToOrganizationName(buildingName: string): string | null {
    if (!buildingName) { return null; }
    switch (buildingName) {
      case 'icon_siam':
        return 'ICONSIAM';
      case 'ics':
        return 'ICS';
      case 'onesiam':
      case 'onesiam_plus':
        return 'ONESIAM';
      default:
        return null;
    }
  }

  get isConfigReady() {
    return this.isConfigReady$.value;
  }

  async fetchDataStatus() {
    const requestURL = '/retail_customer_api_v2/api/v2/info/data-status';
    return this.dataService.fetchData(requestURL);
  }

  async fetchInAppmessageAlert() {
    const requestURL = `/retail_customer_api_v2/api/v2/info/inapp-msg?version=${APP_VERSION}`;
    return this.dataService.fetchData(requestURL);
  }

  async fetchUserSettings() {
    const requestURL = `/retail_customer_api_v2/api/v2/system/user-configuration`;
    return this.dataService.fetchData(requestURL);
  }

  async updateUserOrganizationSetting(org: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    const httpBody = {
      org
    };
    const requestURL = `${BACKENDV2_URL}/api/v2/system/update-selected-org`;
    const requestURLwithIdToken = await this.authenticationService.requestURLwithIdToken(requestURL, false);
    return lastValueFrom(this.http.post(requestURLwithIdToken, httpBody, httpOptions));
  }

  async fetchCheckAppisLatest() {
    const requestURL = `/retail_customer_api_v2/api/v2/system/check-update?version=${APP_VERSION}`;
    return this.dataService.fetchData(requestURL);
  }

  async seenCheckAppisLatest() {
    const requestURL = `/retail_customer_api_v2/api/v2/system/read-update-alert`;
    return this.dataService.fetchData(requestURL);
  }

  get isMobilePlatform() {
    return this.platform.is('hybrid');
  }

  get currentOrganization() {
    return this.currentOrganization$.getValue();
  }

}
