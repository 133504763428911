import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { timeout, catchError, lastValueFrom, of } from 'rxjs';
import { BACKENDV2_CACHE_PREDICTOR_URL, BACKENDV2_URL } from './configs/api';
import { AuthenticationService } from './services/authentication.service';
import { ConfigDataService } from './services/config-data.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  private authService: AuthenticationService;
  private configDataService: ConfigDataService;

  constructor(
    private http: HttpClient,
    private injector: Injector,
  ) {
    // Defers the instantiation of AuthenticationService, ConfigDataService to break the circular dependency.
    // This allows both services to be created independently, avoiding the circular dependency issue.
    setTimeout(() => {
      this.authService = injector.get(AuthenticationService);
      this.configDataService = injector.get(ConfigDataService);
    });
  }

  // private readonly url1 = BACKENDV2_CACHE_PREDICTOR_URL;
  // private readonly url1 = BACKENDV2_URL;
  private readonly url2 = BACKENDV2_URL;
  private readonly requestTimeout = 10000; // Request timeout in milliseconds.

  // Helper function to combine base URL and path
  private concatUrl(base: string, path: string): string {
    return new URL(path, base).toString();
  }

  /**
   * Checks if the URL has an idToken query parameter and adds it if not present.
   *
   * @param url - The URL to check and update.
   * @param idToken - The idToken to add as a query parameter if not present.
   * @returns The updated URL.
   */
  private async addIdTokenQueryParam(url: string): Promise<string> {
    const urlObj = new URL(url);
    const queryParams = new URLSearchParams(urlObj.search);

    const idToken = await this.authService.getIdToken();
    queryParams.set('idToken', idToken);
    urlObj.search = queryParams.toString();

    if (this.configDataService.currentOrganization) {
      const orgQueryParam = this.configDataService.currentOrganization;
      queryParams.set('org', orgQueryParam);
      urlObj.search = queryParams.toString();
    }

    return urlObj.toString();
  }

  /**
   * Fetches data from the given path combined with URL-1 and falls back to URL-2 if URL-1 fails or takes too long.
   * Returns an Observable of the fetched data.
   *
   * @param path - The path to be combined with the base URLs for fetching data.
   * @returns An Observable containing the fetched data.
   */
  async fetchData(path: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      withCredentials: true,
      credentials: 'include'
    };
    // TODO: put URL, path to generic
    // const combinedUrl1 = this.concatUrl(this.url1, path);
    const combinedUrl2 = this.concatUrl(this.url2, path);
    // const url1updatedPath = await this.addIdTokenQueryParam(combinedUrl1);
    const url2updatedPath = await this.addIdTokenQueryParam(combinedUrl2);

    // const request1 = this.http.get(url1updatedPath, httpOptions).pipe(
    //   timeout(this.requestTimeout),
    //   catchError((error) => {
    //     console.error(`Error fetching data from URL 1: ${error}`);
    //     return request2;
    //   })
    // );

    const request2 = this.http.get(url2updatedPath, httpOptions).pipe(
      catchError((error) => {
        console.error(`Error fetching data from URL 2: ${error}`);
        return of(error);
      })
    );

    return lastValueFrom(request2);
  }
}
