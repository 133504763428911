/* eslint-disable max-len */
import { BehaviorSubject } from 'rxjs';
import { EventDependency } from '../enum/event-dependency.enum';
import { GraphDependency } from '../enum/graph-dependency.enum';
import { EventDataService } from '../services/event-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { BaseDataDependency, BaseDataServiceInstance } from './baseGraphData';

export type SelectableLineChartDataName = 'BUILDING_ENTRANCE_EXIT' | 'FLOOR_ENTRANCE_EXIT' | 'ZONE_ENTRANCE_EXIT' | 'EST_TOURIST' | 'PARKING' | 'BUILDING_HOUR_ACCUMULATE' | 'FLOOR_HOUR_ACCUMULATE' | 'ZONE_HOUR_ACCUMULATE' |
    'PREDICTION_BUILDING_ENTRANCE_EXIT' | 'PREDICTION_FLOOR_ENTRANCE_EXIT' | 'PREDICTION_ZONE_ENTRANCE_EXIT' | 'BUILDING_HOUR_ENTRANCE' | 'FLOOR_HOUR_ENTRANCE' | 'ZONE_HOUR_ENTRANCE' | 'ZONE_AREA_ENTRANCE_EXIT' | 'ZONE_AREA_ENTRANCE_EXIT_BY_HOUR' |
    'BUILDING_AVG_TIMESPENT' | 'BUILDING_ENTRANCE_EXIT_FLOW' | 'VEHICLE_PARKING_AREA_ENTRANCE_HOUR' | 'VEHICLE_PARKING_AREA_ENTRANCE_EXIT' | 'VEHICLE_PARKING_ENTRANCE_EXIT_HOUR' | 'STAFF_VISITOR_TRAFFIC' | 'BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK' |
    'BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK_GROUP_DAY_WEEK' | 'BUILDING_ENTRANCE_EXIT_AVG' | 'PREDICTION_BUILDING_ENTRANCE_EXIT_AVG' | 'BUILDING_HOUR_ENTRANCE_AVG' | 'BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_WEEK_GROUP_DAY_WEEK' | 'BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY' |
    'VEHICLE_PARKING_TRAFFIC_TREND' | 'VEHICLE_PARKING_TRAFFIC_HOUR' | 'FLOOR_AREA_ENTRANCE_EXIT' | 'STORE_AREA_TRAFFIC_TREND' | 'VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY' | 'BUILDING_AREA_ENTRANCE_EXIT';
export type SelectableBarChartDataName = 'CURRENT_BUILDING_ENTRANCE_EXIT' | 'CURRENT_FLOOR_ENTRANCE_EXIT' | 'CURRENT_ZONE_ENTRANCE_EXIT' | 'CURRENT_BUILDING_AVG_TIME_SPENT' | 'CURRENT_FLOOR_AVG_TIME_SPENT' | 'CURRENT_ZONE_AVG_TIME_SPENT' |
    'FLOW_BUILDING_ENTRANCE_EXIT' | 'FLOW_FLOOR_ENTRANCE_EXIT' | 'FLOW_ZONE_ENTRANCE_EXIT' | 'PLATE_PROVINCE_VEHICLE_DATA' | 'VEHICLE_PARKING_PROVINCE_UNGROUP' | 'ALL_ZONE_TRAFFIC' | 'ALL_ZONE_AREA_AVG_TIMESPENT' | 'BUILDING_ZONE_SYNERGY' |
    'ZONE_TO_ZONE_SYNERGY' | 'BUILDING_ZONE_SYNERGY_RAW' | 'CURRENT_BUILDING_ENTRANCE_EXIT_FLOW' | 'ALL_ZONE_AREA_AVG_TIMESPENT_REID' | 'BUILDING_UNIQUE_TRAFFIC_BREAKDOWN' | 'VEHICLE_PARKING_AVG_TIMESPENT' |
    'VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP' | 'VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY' | 'CURRENT_BUILDING_ENTRANCE_EXIT_FLOW_RAW' | 'BUILDING_AREA_ENTRANCE_EXIT_HOUR' | 'CURRENT_BUILDING_ENTRANCE_EXIT_FLOW_RAW_AVG' |
    'VEHICLE_PARKING_VEHICLE_PROFILE_AVG_TIMESPENT' | 'FLOOR_AREA_AVG_TIMESPENT' | 'FLOOR_AREA_ENTRANCE_EXIT_BREAKDOWN' | 'STORE_AREA_TRAFFIC_BREAKDOWN';
export type SelectableGoalDataName = 'GOAL_BUILDING_ENTRANCE_EXIT' | 'GOAL_BUILDING_NET_SHOPPING_TIME' | 'GOAL_ZONE_ENTRANCE_EXIT' | 'GOAL_ZONE_NET_SHOPPING_TIME' | 'GOAL_TEENAGER_PROFILE' | 'GOAL_BUILDING_ENTRANCE_EXIT_SUM_DAY' |
    'GOAL_4UP_REVISITED' | 'GOAL_VEHICLE_LUXURY' | 'GOAL_BUILDING_AVG_TRAFFIC' | 'GOAL_ZONE_AVG_TRAFFIC' | 'GOAL_BRAND_AVG_TRAFFIC' | 'GOAL_BUILDING_AVG_TRAFFIC_2';
export type SelectableReportDataName = 'MALL_TRAFFIC' | 'ZONE_TRAFFIC' | 'MALL_COURIER_TRAFFIC' | 'MALL_AVG_VISIT_DURATION' | 'MALL_TOTAL_VISIT_DURATION' | 'MALL_BUSIEST_TIME' | 'MALL_MASK_USE' | 'MALL_SHOPPING_BAG_RATE' |
    'MALL_VEHICLE_TRAFFIC' | 'MALL_VEHICLE_PURCHASING_POWER_LUXURY' | 'MALL_VEHICLE_PURCHASING_POWER_PREMIUM' | 'MALL_REPEATED_VISITOR';
export type SelectableCustomDataName = 'ZONE_GENDER_PROFILE' | 'ZONE_NON_ASIAN_PROFILE' | 'ZONE_AGE_PROFILE' | 'ZONE_AGE_PROFILE_LINE' | 'BUILDING_AGE_PROFILE' | 'BUILDING_GENDER_PROFILE' | 'BUILDING_NON_ASIAN_PROFILE' | 'FLOOR_ENTRANCE_EXIT_BY_HOUR' | 'PLATE_TIMESPENT' |
    'MODE_TRANSPORTATION_TREND' | 'FREQ_VISIT_PURCHASING_POWER' | 'FREQ_VISIT_SEMI_ANNUAL' | 'OVERALL_POPULAR_TIME_LINE' | 'OVERALL_BUSIEST_TIME' | 'AREA_AGE_PROFILE_SAMPLE' | 'AREA_GENDER_PROFILE_SAMPLE' | 'MESSENGER_BRAND_BAR_CHART' |
    'SHOPPING_BAG_COLOR_GRAPH' | 'MESSENGER_BRAND_BY_HOUR_GRAPH' | 'CUSTOMER_VEHICLE_TRAFFIC_GRAPH' | 'CUSTOMER_VEHICLE_PROVINCE_TRAFFIC_GRAPH' | 'MODE_TRANSPORTATION_BREAKDOWN_BAR' | 'VEHICLE_PURCHASING_POWER' | 'TRAFFIC_SITE_COUNT_TREND' | 'TRAFFIC_SITE_UNIQUE_VISITOR_TREND' |
    'TRAFFIC_SITE_PURCHASING_POWER' | 'TRAFFIC_SITE_TRANSPORTATION_TREND' | 'TRAFFIC_SITE_CAR_BRAND_BREAKDOWN' | 'TRAFFIC_SITE_TRANSPORTATION_BREAKDOWN' | 'TRAFFIC_SITE_PURCHASING_POWER_TREND' | 'TRAFFIC_SITE_TRANSPORTATION_PUBLIC_PRIVATE_TREND' | 'TRAFFIC_SITE_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN' |
    'TRAFFIC_SITE_COUNT_BY_HOUR' | 'TRAFFIC_SITE_CAR_BRAND_TREND' | 'TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER' | 'TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_TREND' | 'TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_BREAKDOWN' |
    'TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_BREAKDOWN' | 'TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_PUBLIC_PRIVATE_TREND' | 'TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN' | 'TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER_TREND' |
    'TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_TREND' | 'TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_BREAKDOWN' | 'VEHICLE_PARKING_CAR_BRAND_BREAKDOWN_TOP_TEN' | 'VEHICLE_PARKING_PROVINCE_TREND' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_TREND' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR_TREND' |
    'VEHICLE_PARKING_CONVERSION_RATE_TREND' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_TREND' | 'VEHICLE_PARKING_PURCHASING_POWER_BREAKDOWN' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_P2_TREND' | 'VEHICLE_PARKING_CONVERSION_RATE_P2_TREND' | 'MOCK_STORE_COUNT_TREND' | 'MOCK_STORE_VISITOR_TREND' |
    'MOCK_STORE_VISITOR_HOUR_TREND' | 'MOCK_ADS_VISITOR_PROFILE_TREND' | 'MOCK_ADS_VISITOR_PROFILE_HOUR_TREND' | 'MOCK_ADS_GENDER_PROFILE_TREND' | 'MOCK_ADS_AGE_PROFILE_TREND' | 'MOCK_ADS_LOCAL_PROFILE_TREND' | 'MOCK_ADS_PROFESSION_PROFILE_TREND' |
    'MOCK_ADS_GENDER_PROFILE_BREAKDOWN' | 'MOCK_ADS_AGE_PROFILE_BREAKDOWN' | 'MOCK_ADS_LOCAL_PROFILE_BREAKDOWN' | 'MOCK_ADS_PROFESSION_PROFILE_BREAKDOWN' | 'BUILDING_ZONE_SYNERGY_BY_PIN_BREAKDOWN' | 'GENDER_PROFILE_BY_PIN_PIE' | 'BUILDING_TIMESPENT_BY_PIN' | 'PURCHASE_RATE_BY_PIN_TREND' |
    'BUILDING_MESSENGER_BRAND_BY_PIN_BREAKDOWN' | 'ZONE_AREA_ENTRANCE_EXIT_BY_PIN_TREND' | 'ZONE_AREA_ENTRANCE_EXIT_TREND' | 'ZONE_AREA_AVG_TIMESPENT_TREND' | 'ZONE_AREA_GENDER_PIE' | 'ZONE_AREA_AGE_PROFILE_BAR' | 'ZONE_AREA_PURCHASE_RATE_TREND' | 'ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR_TREND' |
    'ZONE_AREA_ENTRANCE_EXIT_BY_HOUR_TREND' | 'ZONE_AREA_TO_ZONE_SYNERGY_BREAKDOWN' | 'ZONE_TIMESPENT' | 'ZONE_AREA_BUSIEST_TIME' | 'ZONE_AREA_BY_PIN_BUSIEST_TIME' | 'BUILDING_TIMESPENT_BREAKDOWN' | 'BUILDING_ZONE_SYNERGY_BREAKDOWN' | 'ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN' |
    'BUILDING_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN' | 'FLOOR_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN' | 'STORE_AREA_ENTRANCE_EXIT_TREND' | 'STORE_AREA_ENTRANCE_EXIT_BREAKDOWN' | 'STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN' | 'STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN_2' | 'STORE_AREA_TO_ZONE_SYNERGY_BREAKDOWN' | 'STORE_AREA_TO_ZONE_SYNERGY_RAW_BREAKDOWN' |
    'STORE_AREA_PROXIMITY_TREND' | 'STORE_AREA_PROXIMITY_HOUR_BREAKDOWN' | 'BUILDING_ENTRANCE_EXIT' | 'FLOOR_ENTRANCE_EXIT' | 'BUILDING_HOUR_ENTRANCE' | 'FLOOR_HOUR_ENTRANCE' | 'AGE_PROFILE_BREAKDOWN' | 'BUILDING_BUSIEST_TIME' | 'BUILDING_BUSIEST_TIME_AVG' | 'BUILDING_TOTAL_VISIT_DURATION_PERCENTAGE' |
    'ZONE_TOTAL_VISIT_DURATION_PERCENTAGE' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_TREND_ALL_AREA' | 'VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_TREND_ALL_AREA' | 'BUILDING_TO_BUILDING_SYNERGY_BREAKDOWN' | 'BUILDING_TO_BUILDING_SYNERGY_RAW_BREAKDOWN' | 'FLOOR_STORE_AREA' | 'FLOOR_STORE_AREA_CATEGORY' |
    'VEHICLE_PARKING_MODE_TRANSPORTATION_TREND_ALL_AREA_HOUR' | 'BUILDING_TRAFFIC_BREAKDOWN' | 'BUILDING_VISITOR_BREAKDOWN' | 'FLOOR_AREA_ENTRANCE_EXIT_PIN' | 'BUILDING_UNQIUE_TRAFFIC_BREAKDOWN' | 'PRED_TRAFFIC_SITE_PURCHASING_POWER' | 'PRED_TRAFFIC_SITE_TRANSPORTATION_BREAKDOWN' |
    'PRED_TRAFFIC_SITE_CAR_BRAND_BREAKDOWN' | 'TRAFFIC_SITE_ADS_EXPOSURE_HOUR_TREND' | 'PRED_TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER' | 'PRED_TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_BREAKDOWN' | 'PRED_TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_BREAKDOWN' |
    'TRAFFIC_SITE_PACKAGE_COUNT_TREND' | 'TRAFFIC_SITE_PACKAGE_PURCHASING_POWER' | 'TRAFFIC_SITE_PACKAGE_TRANSPORTATION_TREND' | 'TRAFFIC_SITE_PACKAGE_TRANSPORTATION_BREAKDOWN' | 'TRAFFIC_SITE_PACKAGE_TRANSPORTATION_PUBLIC_PRIVATE_TREND' | 'TRAFFIC_SITE_PACKAGE_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN' |
    'TRAFFIC_SITE_PACKAGE_CAR_BRAND_BREAKDOWN' | 'TRAFFIC_SITE_PACKAGE_PURCHASING_POWER_TREND' | 'TRAFFIC_SITE_PACKAGE_COUNT_HOUR' | 'TRAFFIC_SITE_PACKAGE_CAR_BRAND_TREND' | 'TRAFFIC_SITE_PACKAGE_ADS_EXPOSURE_HOUR_TREND' | 'VEHICLE_PARKING_PROVINCE_UNGROUP_TREND' |
    'VEHICLE_PARKING_ALL_AREA_PURCHASING_POWER_BREAKDOWN' | 'VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT' | 'VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT_PERCENTAGE' | 'VEHICLE_PARKING_PLATE_NUMBER_DEFINITON_PERCENTAGE' | 'VEHICLE_PARKING_TRAFFIC_TREND' |
    'VEHICLE_PARKING_PURCHASING_POWER' | 'VEHICLE_PARKING_PURCHASING_POWER' | 'VEHICLE_PARKING_PURCHASING_POWER_TREND' | 'VEHICLE_PARKING_TRANSPORTATION_TREND' | 'VEHICLE_PARKING_TRANSPORTATION_BREAKDOWN' | 'VEHICLE_PARKING_CAR_BRAND_BREAKDOWN' | 'VEHICLE_PARKING_CAR_BRAND_TREND' | 'VEHICLE_PARKING_TRAFFIC_HOUR' |
    'VEHICLE_PARKING_BY_PIN_TRAFFIC_TREND' | 'VEHICLE_PARKING_BY_PIN_PURCHASING_POWER' | 'VEHICLE_PARKING_BY_PIN_PURCHASING_POWER' | 'VEHICLE_PARKING_BY_PIN_PURCHASING_POWER_TREND' | 'VEHICLE_PARKING_BY_PIN_TRANSPORTATION_TREND' | 'VEHICLE_PARKING_BY_PIN_TRANSPORTATION_BREAKDOWN' |
    'VEHICLE_PARKING_BY_PIN_CAR_BRAND_BREAKDOWN' | 'VEHICLE_PARKING_BY_PIN_CAR_BRAND_TREND' | 'VEHICLE_PARKING_BY_PIN_TRAFFIC_HOUR' | 'VEHICLE_PARKING_PROFILE_TRAFFIC_BREAKDOWN' | 'VEHICLE_PARKING_PROFILE_BY_PIN_TRAFFIC_BREAKDOWN' | 'VEHICLE_PARKING_PROFILE_PLATE_NUMBER_DEFINITION_TREND' |
    'VEHICLE_PARKING_PROFILE_PLATE_NUMBER_DEFINITION_BREAKDOWN' | 'VEHICLE_PARKING_ENTRANCE_EXIT_BREAKDOWN' | 'VEHICLE_PARKING_PROFILE_BY_PIN_PLATE_NUMBER_DEFINITION_TREND' | 'VEHICLE_PARKING_PROFILE_BY_PIN_PLATE_NUMBER_DEFINITION_BREAKDOWN' | 'ZONE_TIMESPENT_BUCKET' | 'ZONE_VISITOR_TIMESPENT' |
    'ZONE_VISITOR_TRAFFIC_TIMESPENT' | 'STORE_FRONT_BACK_PROX_BREAKDOWN_PERCENTAGE' | 'BUILDING_ENTRANCE_EXIT_BY_PIN_BREAKDOWN' | 'ZONE_ENTRANCE_EXIT_BY_PIN_BREAKDOWN' | 'EVENT_TRAFFIC_TREND' | 'VEHICLE_PARKING_FREQ_VISIT' | 'VEHICLE_PARKING_REPEATED_VISITORS' |
    'OVERALL_VEHICLE_PARKING_PURCHASING_POWER' | 'OVERALL_VEHICLE_PARKING_CAR_BRAND' | 'BUILDING_FLOOR_ENTRANCE_EXIT_GROUPBY_PIN' | 'BRAND_TRAFFIC' | 'BUILDING_FLOOR_ENTRANCE_EXIT_GROUPBY_PIN_AVG' | 'BUILDING_ETHNICITY_PROFILE_BY_PIN' | 'BUILDING_ETHNICITY_PURCHASE_RATE_BY_PIN' | 'BUILDING_CUSTOM_SEGMENT_PURCHASE_RATE_BY_PIN' |
    'STORE_CUSTOM_SEGMENT_PURCHASE_RATE' | 'STORE_ETHNICITY_PROFILE' | 'VEHICLE_PARKING_VEHCILE_PROFILE_ENTRANCE_EXIT_BREAKDOWN' | 'VEHICLE_PARKING_AREA_PLATE_TIMESPENT' | 'VEHICLE_PARKING_FREQ_VISIT_PROFILE' | 'VEHICLE_PARKING_REPEATED_VISITORS_PROFILE_PERCENTAGE' | 'VEHICLE_PARKING_PLATE_TIMESPENT_PROFILE' | 'VEHICLE_PARKING_AREA_PURCHASING_POWER_BREAKDOWN' |
    'VEHICLE_PARKING_AREA_VEHICLE_TYPE_BREAKDOWN' | 'VEHICLE_PARKING_AREA_TOP10_CAR_BRANDS_BREAKDOWN' | 'VEHICLE_PARKING_AREA_REPEATED_VISITORS';
export type SelectableNumberCardDataName = 'MALL_TRAFFIC' | 'MALL_VEHICLE_TRAFFIC' | 'MALL_AVG_WEEKDAY_TRAFFIC' | 'MALL_AVG_WEEKEND_TRAFFIC' | 'STAFF_TRAFFIC' | 'COURIER_TRAFFIC' | 'MALL_AVG_DURATION_OF_VISIT' | 'MALL_TOTAL_VISIT_DURATION' | 'MALL_SHOPPING_BAG_RATE' |
    'MALL_MASK_USE' | 'CUSTOMER_VEHICLE_TRAFFIC' | 'STAFF_VEHICLE_TRAFFIC' | 'MESSENGER_BRAND_TRAFFIC' | 'MALL_AVG_WEEKDAY_VEHICLE_TRAFFIC' | 'MALL_AVG_WEEKEND_VEHICLE_TRAFFIC' | 'MALL_VEHICLE_UNIQUE_VISITORS' | 'TRAFFIC_SITE_COUNT' | 'TRAFFIC_SITE_UNIQUE_VISITOR' |
    'TRAFFIC_SITE_AVG_WEEKDAY_COUNT' | 'TRAFFIC_SITE_AVG_WEEKEND_COUNT' | 'TRAFFIC_SITE_PEAK_TIME' | 'TRAFFIC_SITE_OFF_PEAK_TIME' | 'TRAFFIC_SITE_AVG_VISITOR' | 'VEHICLE_PARKING_TRAFFIC' | 'VEHICLE_PARKING_PROXIMITY' | 'VEHICLE_PARKING_CONVERSION_RATE' | 'VEHICLE_PARKING_TRAFFIC_P2' | 'VEHICLE_PARKING_TRAFFIC_P3' |
    'VEHICLE_PARKING_PROXIMITY_P2' | 'VEHICLE_PARKING_CONVERSION_RATE_P2' | 'MOCK_STORE_FOOT_TRAFFIC' | 'MOCK_STORE_AVG_WEEKDAY_FOOT_TRAFFIC' | 'MOCK_STORE_AVG_WEEKEND_FOOT_TRAFFIC' | 'MOCK_STORE_VISITOR' | 'MOCK_STORE_AVG_WEEKDAY_VISITOR' | 'MOCK_STORE_AVG_WEEKEND_VISITOR' |
    'MOCK_STORE_CONVERSION_RATE' | 'MOCK_STORE_VISITOR_AVG_TIME_SPENT' | 'MOCK_STORE_VISITOR_PEAK_TIME' | 'MOCK_ADS_VISITOR_PROFILE_COUNT' | 'MOCK_ADS_AVG_TIME_EXPOSE' | 'ZONE_TRAFFIC_BY_PIN' | 'ZONE_AVG_WEEKDAY_TRAFFIC_BY_PIN' | 'ZONE_AVG_WEEKEND_TRAFFIC_BY_PIN' | 'ZONE_AVG_TIMESPENT_BY_PIN' | 'ZONE_PURCHASE_RATE_BY_PIN' |
    'ALL_ZONE_TRAFFIC' | 'ALL_ZONE_AREA_AVG_WEEKDAY' | 'ALL_ZONE_AREA_AVG_WEEKEND' | 'ALL_ZONE_AVG_TIMESPENT' | 'ALL_ZONE_PURCHASE_RATE' | 'BUILDING_AVG_TIMESPENT' | 'BUILDING_AVG_WEEKDAY_TRAFFIC' | 'BUILDING_AVG_WEEKEND_TRAFFIC' | 'STORE_AREA_TRAFFIC' | 'STORE_AREA_AVG_TIMESPENT' | 'STORE_AREA_AVG_WEEKDAY_TRAFFIC' | 'STORE_AREA_AVG_WEEKEND_TRAFFIC' |
    'STORE_AREA_BUSIEST_TIME' | 'BUILDING_TRAFFIC' | 'FLOOR_TRAFFIC' | 'ZONE_AREA_TRAFFIC' | 'ALL_ZONE_AVG_TIMESPENT_REID' | 'BUILDING_AVG_TIMESPENT_REID' | 'BUILDING_AVG_TIMESPENT_BY_PIN' | 'BUILDING_TOTAL_VISIT_DURATION' | 'BUILDING_AREA_STUDENT_PROFILE_TRAFFIC' | 'BUILDING_AREA_MASK_PROFILE_RATE' | 'VEHICLE_PARKING_AREA_TRAFFIC' |
    'PRED_TRAFFIC_SITE_COUNT' | 'PRED_TRAFFIC_SITE_ADS_EXPOSURE' | 'PRED_TRAFFIC_SITE_UNIQUE_VISITOR' | 'PRED_TRAFFIC_SITE_AVG_VISITOR' | 'TRAFFIC_SITE_PACKAGE_COUNT' | 'TRAFFIC_SITE_PACKAGE_PEAK_TIME' | 'TRAFFIC_SITE_PACKAGE_OFF_PEAK_TIME' | 'TRAFFIC_SITE_EXPOSURE_TIME' | 'TRAFFIC_SITE_PACKAGE_EXPOSURE_TIME' |
    'CURRENT_VEHICLE_PARKING_AVG_TIMESPENT' | 'VEHICLE_PARKING_AVG_WEEKDAY_TRAFFIC' | 'VEHICLE_PARKING_AVG_WEEKEND_TRAFFIC' | 'VEHICLE_PARKING_VEHICLE_TRAFFIC' | 'VEHICLE_PARKING_PEAK_TIME' | 'VEHICLE_PARKING_OFF_PEAK_TIME' |
    'VEHICLE_PARKING_BY_PIN_VEHICLE_TRAFFIC' | 'VEHICLE_PARKING_BY_PIN_PEAK_TIME' | 'VEHICLE_PARKING_BY_PIN_OFF_PEAK_TIME' | 'VEHICLE_PARKING_PROFILE_AVG_TIMESPENT' | 'VEHICLE_PARKING_PROFILE_BY_PIN_AVG_TIMESPENT' | 'VEHICLE_PARKING_PLATE_NUMBER_DEFINITION_TRAFFIC' | 'FLOOR_AVG_TIMESPENT' | 'FLOOR_NET_SHOPPING_TIME' |
    'ZONE_AVG_TIMESPENT_UPPER5' | 'ZONE_VISITOR_AVG_TIMESPENT_LOWER5' | 'STORE_FRONT_PROX_TRAFFIC' | 'STORE_BACK_PROX_TRAFFIC' | 'STORE_EST_PROX_TRAFFIC_RANGE' | 'ZONE_CONV_RATE' | 'TRAFFIC_ADS_FREQUENCY' | 'VISITOR_TRAFFIC' | 'MALL_TRAFFIC_AVG' | 'TRAFFIC_SITE_PACKAGE_PEAK_TIME_2' | 'STAFF_TRAFFIC_COUNT' | 'STAFF_TRAFFIC_COUNT_2' |
    'FEMALE_TRAFFIC_COUNT' | 'MALE_TRAFFIC_COUNT' | 'STORE_SHOPPING_BAG_RATE' | 'MALE_COUNT_BY_PIN' | 'FEMALE_COUNT_BY_PIN' | 'MALE_BUILDING_COUNT' | 'FEMALE_BUILDING_COUNT' | 'VEHICLE_PARKING_NEW_VISITOR' | 'VEHICLE_PARKING_LOSS_VISITOR' | 'VEHICLE_PARKING_REGULAR_VISITOR' | 'VEHICLE_PARKING_CHURN_VISITOR' |
    'VEHICLE_PARKING_VEHICLE_PROFILE_AVG_WEEKDAY_TRAFFIC' | 'VEHICLE_PARKING_VEHICLE_PROFILE_AVG_WEEKEND_TRAFFIC' | 'BUILDING_UNIQUE_TRAFFIC' | 'VEHICLE_PARKING_NEW_VISITOR_PROFILE' | 'VEHICLE_PARKING_LOSS_VISITOR_PROFILE' | 'VEHICLE_PARKING_REGULAR_VISITOR_PROFILE' | 'VEHICLE_PARKING_CHURN_VISITOR_PROFILE' | 'VEHICLE_PARKING_TIMEPSENT_PROFILE' |
    'VEHICLE_PARKING_AERA_AVG_WEEKDAY_TRAFFIC' | 'VEHICLE_PARKING_AREA_AVG_WEEKEND_TRAFFIC' | 'BUILDING_AREA_TRAFFIC';
export type SelectableSankeyDataName = 'FLOW_BUILDING_ENTRANCE_EXIT' | 'FLOW_FLOOR_ENTRANCE_EXIT' | 'FLOW_ZONE_ENTRANCE_EXIT' | 'BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN' | 'BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN' | 'BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN' | 'BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN' | 'STORE_ZONE_TRAFFIC_FLOW' |
    'BUILDING_ZONE_TRAFFIC_FLOW';
export type SelectablePieDataName = 'GENDER_PROFILE_BY_PIN_PIE';
export type SelectableHeatmapDataName = 'ZONE_TO_ZONE_SYNERGY' | 'PIN_TO_ZONE_SYNERGY' | 'PARKING_RETENTION' | 'VEHICLE_PARKING_TO_PARKING_SYNERGY' | 'VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE';
export type SelectableDividerSelectorDataName = 'VEHICLE_PROFILE';
export type SelectableDataName = SelectableLineChartDataName | SelectableBarChartDataName | SelectableGoalDataName | SelectableReportDataName | SelectableCustomDataName | SelectableNumberCardDataName | SelectableDividerSelectorDataName | SelectableSankeyDataName | SelectableHeatmapDataName;

export class SelectableData {
    public static readonly SelectableLineDataMapper: { [key in SelectableLineChartDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> } } = {
        BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitData$, },
        BUILDING_AREA_ENTRANCE_EXIT: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaEntranceExitData$, },
        FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.entranceExitFloorData$, },
        ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE, data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceExitFloorData$, },
        EST_TOURIST: { dependecies: GraphDependency.TOURIST, data: (graphDataService: GraphDataService) => graphDataService.mallTrafficTouristsChartData$, },
        PARKING: { dependecies: GraphDependency.PARKING_CUSTOMER, data: (graphDataService: GraphDataService) => graphDataService.parkingCustomerEntranceExitChartData$, },
        BUILDING_HOUR_ACCUMULATE: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.accumulateHeadcountByHour$, },
        FLOOR_HOUR_ACCUMULATE: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, data: (graphDataService: GraphDataService) => graphDataService.floorAccumulateHeadcountByHour$, },
        ZONE_HOUR_ACCUMULATE: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.zoneAccumulateHeadcountByHour$, },
        PREDICTION_BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.PREDICTION_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.predictedBuildingTrafficNumber$, },
        PREDICTION_FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.PREDICTION_ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.predictedFloorTrafficNumber$, },
        PREDICTION_ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.PREDICTION_ENTRANCE_EXIT_ZONE, data: (graphDataService: GraphDataService) => graphDataService.predictedZoneTrafficNumber$, },
        BUILDING_HOUR_ENTRANCE: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceByHour$, },
        BUILDING_HOUR_ENTRANCE_AVG: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR_NUMBER_CARD, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceByHourAvgPerDay$, },
        FLOOR_HOUR_ENTRANCE: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceByHour$, },
        ZONE_HOUR_ENTRANCE: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceByHour$, },
        ZONE_AREA_ENTRANCE_EXIT: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAreaEntranceExitTrend$ },
        ZONE_AREA_ENTRANCE_EXIT_BY_HOUR: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.allZoneEntranceExitByHour$ },
        BUILDING_AVG_TIMESPENT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.averageTimeSpentChartData$ },
        BUILDING_ENTRANCE_EXIT_FLOW: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, data: (graphDataService: GraphDataService) => graphDataService.buildingFlowEntranceExitTrendData$ },
        VEHICLE_PARKING_AREA_ENTRANCE_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingVehicleByHourTrend$ },
        VEHICLE_PARKING_AREA_ENTRANCE_EXIT: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingEntranceExitTrend$ },
        VEHICLE_PARKING_ENTRANCE_EXIT_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingEntranceExitHour$ },
        STAFF_VISITOR_TRAFFIC: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.staffVisitorClassTrendData$ },
        BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaAverageDayOfWeekData$ },
        BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK_GROUP_DAY_WEEK: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_AVG_DAY_WEEK, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaAverageDayOfWeekGroupDayWeekData$ },
        BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_WEEK_GROUP_DAY_WEEK: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_WEEK, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaDayOfWeekGroupDayWeekData$ },
        BUILDING_ENTRANCE_EXIT_AVG: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitAvgByDayData$ },
        PREDICTION_BUILDING_ENTRANCE_EXIT_AVG: { dependecies: GraphDependency.PREDICTION_BUILDING_ENTRANCE_EXIT_AVG, data: (graphDataService: GraphDataService) => graphDataService.predictedBuildingTrafficNumberAvg$ },
        BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaDayOfWeekGroupDayByDayData$ },
        VEHICLE_PARKING_TRAFFIC_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountTrend$ },
        VEHICLE_PARKING_TRAFFIC_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountByHour$ },
        FLOOR_AREA_ENTRANCE_EXIT: { dependecies: GraphDependency.FLOOR_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.floorAreaEntranceExitTrendData$ },
        STORE_AREA_TRAFFIC_TREND: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.storeAllAreaEntranceExitTrend$ },
        VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_HOUR_SUM_DAY_BY_DAY_GROUP_DAY_BY_DAY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingDayOfWeekGroupDayWeekData$ },
    };

    public static readonly SelectableBarDataMapper: { [key in SelectableBarChartDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> } } = {
        CURRENT_BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountData$, },
        CURRENT_FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountFloorData$, },
        CURRENT_ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountZoneData$, },
        CURRENT_BUILDING_AVG_TIME_SPENT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAverageTimeSpentData$ },
        CURRENT_FLOOR_AVG_TIME_SPENT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.currentAvgTimeSpentFloorData$, },
        CURRENT_ZONE_AVG_TIME_SPENT: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE, data: (graphDataService: GraphDataService) => graphDataService.currentAvgTimeSpentZoneData$, },
        FLOW_BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitFlowData$ },
        FLOW_FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceExitFlowData$, },
        FLOW_ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_ZONE, data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceExitFlowData$, },
        PLATE_PROVINCE_VEHICLE_DATA: { dependecies: GraphDependency.VEHICLE_BY_PROVINCE_UNGROUP, data: (graphDataService: GraphDataService) => graphDataService.vehicleProvinceData$, },
        VEHICLE_PARKING_PROVINCE_UNGROUP: { dependecies: GraphDependency.VEHICLE_PARKING_PROVINCE_UNGROUP, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProvinceUngroupBreakdown$ },
        ALL_ZONE_TRAFFIC: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAreaEntranceExit$ },
        ALL_ZONE_AREA_AVG_TIMESPENT_REID: { dependecies: GraphDependency.ALL_ZONE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAvgTimespentDataReID$ },
        BUILDING_ZONE_SYNERGY: { dependecies: GraphDependency.BUILDING_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneSynergy$ },
        BUILDING_ZONE_SYNERGY_RAW: { dependecies: GraphDependency.BUILDING_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneSynergyRaw$ },
        ZONE_TO_ZONE_SYNERGY: { dependecies: GraphDependency.ALL_ZONE_TO_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.allZoneToZoneSynergyData$ },
        CURRENT_BUILDING_ENTRANCE_EXIT_FLOW: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, data: (graphDataService: GraphDataService) => graphDataService.buildingFlowEntranceExitData$ },
        CURRENT_BUILDING_ENTRANCE_EXIT_FLOW_RAW: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, data: (graphDataService: GraphDataService) => graphDataService.buildingFlowEntranceExitRawData$ },
        CURRENT_BUILDING_ENTRANCE_EXIT_FLOW_RAW_AVG: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING_AVG, data: (graphDataService: GraphDataService) => graphDataService.buildingFlowEntranceExitRawAvgData$ },
        ALL_ZONE_AREA_AVG_TIMESPENT: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAreaAvgTimespentBreakdown$ },
        BUILDING_UNIQUE_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.BUILDING_UNIQUE_TRAFFIC_BREAKDOWN, data: (graphDataService: GraphDataService) => graphDataService.buildingUniqueTrafficBreakdown$ },
        VEHICLE_PARKING_AVG_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAvgTimespent$ },
        VEHICLE_PARKING_VEHICLE_PROFILE_AVG_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingVehicleProfileAvgTimespent$ },
        VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP: { dependecies: GraphDependency.VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProvinceEntranceExitBreakdown$ },
        VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY: { dependecies: GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingToVehicleParkingSynergyData$ },
        BUILDING_AREA_ENTRANCE_EXIT_HOUR: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaEntranceExitByHourBreakdown$ },
        FLOOR_AREA_ENTRANCE_EXIT_BREAKDOWN: { dependecies: GraphDependency.FLOOR_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.floorAreaEntranceExitBreakdownData$ },
        FLOOR_AREA_AVG_TIMESPENT: { dependecies: GraphDependency.FLOOR_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.floorAreaAvgTimespentBreakdownData$ },
        STORE_AREA_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.storeAllAreaEntranceExitBreakdown$ },
    };

    public static readonly selectableGoalDataMapper: { [key in SelectableGoalDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        GOAL_BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentGoalTrafficBuildingData$ },
        GOAL_BUILDING_AVG_TRAFFIC: { dependecies: GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentGoalAvgTrafficBuildingData$ },
        GOAL_BUILDING_AVG_TRAFFIC_2: { dependecies: GraphDependency.GOAL_BUILDING_ENTRANCE_EXIT_2, data: (graphDataService: GraphDataService) => graphDataService.currentGoalAvgTrafficBuildingData2$ },
        GOAL_BUILDING_NET_SHOPPING_TIME: { dependecies: [GraphDependency.MONTH_ENTRANCE_EXIT, GraphDependency.PREDICTION_ENTRANCE_EXIT], data: (graphDataService: GraphDataService) => [graphDataService.currentNetShoppingHourGoalData$, graphDataService.predictedBuildingNetShoppingHourNumber$] },
        GOAL_ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.GOAL_ZONE_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentGoalTrafficZoneData$ },
        GOAL_ZONE_AVG_TRAFFIC: { dependecies: GraphDependency.GOAL_ZONE_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentGoalAvgTrafficZoneData$ },
        GOAL_BRAND_AVG_TRAFFIC: { dependecies: GraphDependency.GOAL_ZONE_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentGoalAvgTrafficBrandData$ },
        GOAL_ZONE_NET_SHOPPING_TIME: { dependecies: [GraphDependency.MONTH_ENTRANCE_EXIT_ZONE, GraphDependency.PREDICTION_ENTRANCE_EXIT_ZONE], data: (graphDataService: GraphDataService) => [graphDataService.currentZoneNetShoppingHourGoalData$, graphDataService.predictedZoneNetShoppingNumber$] },
        GOAL_TEENAGER_PROFILE: { dependecies: [GraphDependency.MONTH_VISITOR_PROFILE, GraphDependency.PREDICTION_VISITOR_PROFILE], data: (graphDataService: GraphDataService) => [graphDataService.currentTeenagerGoalData$, graphDataService.predictedTeenagerProfileNumber$] },
        GOAL_BUILDING_ENTRANCE_EXIT_SUM_DAY: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_SUM_BY_DAY, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingTrafficGoalData$ },
        GOAL_4UP_REVISITED: { dependecies: GraphDependency.FREQ_VISIT_SEMI_ANNUAL_SUM_BY_DAY, data: (graphDataService: GraphDataService) => graphDataService.current4UpRevisitedGoalSumData$ },
        GOAL_VEHICLE_LUXURY: { dependecies: GraphDependency.VEHICLE_PURCHASING_POWER_SUM_BY_DAY, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleLuxuryGoalData$ },
    };

    public static readonly selectableReportDataMapper: { [key in SelectableReportDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> } } = {
        MALL_AVG_VISIT_DURATION: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAverageTimeSpentData$, },
        MALL_BUSIEST_TIME: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.busiestTime$, },
        MALL_COURIER_TRAFFIC: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentTotalMessengerData$, },
        MALL_MASK_USE: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentMaskCount$, },
        MALL_REPEATED_VISITOR: { dependecies: GraphDependency.REPETATED_VISITORS, data: (graphDataService: GraphDataService) => graphDataService.currentRepeatedVisitorsData$, },
        MALL_SHOPPING_BAG_RATE: { dependecies: GraphDependency.PURCHASE_RATE, data: (graphDataService: GraphDataService) => graphDataService.currentpurchaseRateData$, },
        MALL_TOTAL_VISIT_DURATION: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingNetShoppingHourData$, },
        MALL_TRAFFIC: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountData$, },
        MALL_VEHICLE_PURCHASING_POWER_LUXURY: { dependecies: GraphDependency.VEHICLE_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.currentLuxuryTierData$, },
        MALL_VEHICLE_PURCHASING_POWER_PREMIUM: { dependecies: GraphDependency.VEHICLE_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.currentPremiumTierData$, },
        MALL_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.currentTotalTransportationData$, },
        ZONE_TRAFFIC: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountZoneData$, },
    };

    // TO-DO: Separate data to be used by graph type
    public static readonly selectableCustomDataMapper: { [key in SelectableCustomDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        ZONE_GENDER_PROFILE: { dependecies: GraphDependency.ZONE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.zoneGenderProfileData$, },
        ZONE_NON_ASIAN_PROFILE: { dependecies: GraphDependency.ZONE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.zoneEthnicityProfileData$, },
        ZONE_AGE_PROFILE: { dependecies: GraphDependency.ZONE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.zoneAgeProfileData$, },
        ZONE_AGE_PROFILE_LINE: { dependecies: GraphDependency.ZONE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.zoneAgeProfileLineData$, },
        BUILDING_AGE_PROFILE: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.ageProfileStackData$, },
        BUILDING_GENDER_PROFILE: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.genderProfileStackData$, },
        BUILDING_NON_ASIAN_PROFILE: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.ethnicityProfileStackData$, },
        FLOOR_ENTRANCE_EXIT_BY_HOUR: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceByHour$, },
        PLATE_TIMESPENT: { dependecies: GraphDependency.PLATE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.plateTimespentBinData$ },
        MODE_TRANSPORTATION_TREND: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.modeOfTransportationChartData$ },
        FREQ_VISIT_PURCHASING_POWER: { dependecies: GraphDependency.FREQ_VISIT_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.frequencyOfVisitWithPurchasingPowerData$ },
        FREQ_VISIT_SEMI_ANNUAL: { dependecies: GraphDependency.FREQ_VISIT_SEMI_ANNUAL, data: (graphDataService: GraphDataService) => graphDataService.frequencyOfVisitSemiAnnualData$ },
        OVERALL_POPULAR_TIME_LINE: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.accumulateHeadcountByHour$ },
        OVERALL_BUSIEST_TIME: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.busiestTime$ },
        AREA_AGE_PROFILE_SAMPLE: { dependecies: [GraphDependency.ZONE_VISITOR_PROFILE_SAMPLE, GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS], data: (graphDataService: GraphDataService) => graphDataService.areaAgeProfileSampleData$ },
        AREA_GENDER_PROFILE_SAMPLE: { dependecies: [GraphDependency.ZONE_VISITOR_PROFILE_SAMPLE, GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS], data: (graphDataService: GraphDataService) => graphDataService.areaGenderProfileSampleData$ },
        MESSENGER_BRAND_BAR_CHART: { dependecies: [GraphDependency.MESSENGER_BRAND], data: (graphDataService: GraphDataService) => graphDataService.messengerBrandBarChartData$ },
        SHOPPING_BAG_COLOR_GRAPH: { dependecies: GraphDependency.PURCHASAE_BAG_COLOR, data: (graphDataService: GraphDataService) => graphDataService.purchaseBagColorListData$ },
        MESSENGER_BRAND_BY_HOUR_GRAPH: { dependecies: GraphDependency.MESSENGER_BRAND_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.messengerBrandbyHourChartData$ },
        CUSTOMER_VEHICLE_TRAFFIC_GRAPH: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.customerVehicleTrafficChartData$ },
        CUSTOMER_VEHICLE_PROVINCE_TRAFFIC_GRAPH: { dependecies: GraphDependency.VEHICLE_BY_PROVINCE, data: (graphDataService: GraphDataService) => graphDataService.customerVehicleByProvinceChartData$ },
        MODE_TRANSPORTATION_BREAKDOWN_BAR: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.currentModeofTransportBarChartData$ },
        VEHICLE_PURCHASING_POWER: { dependecies: GraphDependency.VEHICLE_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.vehiclePurchasingPowerData$ },
        TRAFFIC_SITE_COUNT_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountTrend$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorCountTrend$ },
        TRAFFIC_SITE_PURCHASING_POWER: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePurchasingPowerBreakdown$ },
        TRAFFIC_SITE_TRANSPORTATION_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportTrend$ },
        TRAFFIC_SITE_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportBreakdown$ },
        TRAFFIC_SITE_TRANSPORTATION_PUBLIC_PRIVATE_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportPublicPrivateTrend$ },
        TRAFFIC_SITE_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportPublicPrivateBreakdown$ },
        TRAFFIC_SITE_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileTopTenCarBrandBreakdown$ },
        TRAFFIC_SITE_PURCHASING_POWER_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePurchasingPowerTrend$ },
        TRAFFIC_SITE_COUNT_BY_HOUR: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountByHour$ },
        TRAFFIC_SITE_CAR_BRAND_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCarBrandTrend$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorModeOfTransportTrend$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorModeOfTransportBreakdown$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_PUBLIC_PRIVATE_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteModeOfTransportUniqueVisitorPublicPrivateTrend$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteModeOfTransportUniqueVisitorPublicPrivateBreakdown$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorPurchasingPowerTrend$ },
        TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileUniqueVisitorCarBrandTrend$ },
        VEHICLE_PARKING_CAR_BRAND_BREAKDOWN_TOP_TEN: { dependecies: GraphDependency.VEHICLE_PARKING_CAR_BRAND, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingCarBrandBreakdownTopTen$ },
        VEHICLE_PARKING_PROVINCE_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_PROVINCE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProvinceTrend$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportTrend$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportProxTrend$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_P2_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_P2, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingPKP2ModeofTransportProxTrend$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportbyHourTrend$ },
        VEHICLE_PARKING_CONVERSION_RATE_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingVehicleConversionRateTrend$ },
        VEHICLE_PARKING_CONVERSION_RATE_P2_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P2, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingPKP2VehicleConversionRateTrend$ },
        VEHICLE_PARKING_PURCHASING_POWER_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingPurchasingPowerBreakdown$ },
        // MOCK_STORE_COUNT_TREND: { dependecies: GraphDependency.MOCK_STORE_COUNT, data: (graphDataService: GraphDataService) => graphDataService.mockStoreCountTrend$ },
        MOCK_STORE_COUNT_TREND: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaImpressionTrend$ },
        // MOCK_STORE_VISITOR_TREND: { dependecies: GraphDependency.MOCK_STORE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.mockStoreVisitorTrend$ },
        MOCK_STORE_VISITOR_TREND: { dependecies: GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaEngagementCountTrend$ },
        // MOCK_STORE_VISITOR_HOUR_TREND: { dependecies: GraphDependency.MOCK_STORE_VISITOR_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.mockStoreVisitorByhourTrend$ },
        MOCK_STORE_VISITOR_HOUR_TREND: { dependecies: GraphDependency.LIDAR_AREA_ENGAGEMENT_COUNT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaEngagementCountByhourTrend$ },
        // MOCK_ADS_VISITOR_PROFILE_TREND: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileTrend$ }, 
        MOCK_ADS_VISITOR_PROFILE_TREND: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaImpressionTrend$ },
        MOCK_ADS_GENDER_PROFILE_TREND: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByGenderTrend$ },
        MOCK_ADS_AGE_PROFILE_TREND: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByAgeTrend$ },
        MOCK_ADS_LOCAL_PROFILE_TREND: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByLocalTrend$ },
        MOCK_ADS_PROFESSION_PROFILE_TREND: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByProfessionTrend$ },
        // MOCK_ADS_GENDER_PROFILE_BREAKDOWN: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByGenderBreakdown$ }, 
        // MOCK_ADS_AGE_PROFILE_BREAKDOWN: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByAgeBreakdown$ }, 
        // MOCK_ADS_LOCAL_PROFILE_BREAKDOWN: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByLocalBreakdown$ }, 
        // MOCK_ADS_PROFESSION_PROFILE_BREAKDOWN: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.mockAdsVisitorProfileByProfessionBreakdown$ }, 
        MOCK_ADS_GENDER_PROFILE_BREAKDOWN: { dependecies: GraphDependency.LIDAR_AREA_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaVisitorProfileByGenderBreakdown$ },
        MOCK_ADS_AGE_PROFILE_BREAKDOWN: { dependecies: GraphDependency.LIDAR_AREA_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaVisitorProfileByAgeBreakdown$ },
        MOCK_ADS_LOCAL_PROFILE_BREAKDOWN: { dependecies: GraphDependency.LIDAR_AREA_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaVisitorProfileByLocalBreakdown$ },
        MOCK_ADS_PROFESSION_PROFILE_BREAKDOWN: { dependecies: GraphDependency.LIDAR_AREA_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaVisitorProfileByProfessionBreakdown$ },
        MOCK_ADS_VISITOR_PROFILE_HOUR_TREND: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaImpressionByHourTrend$ },
        BUILDING_ZONE_SYNERGY_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.BUILDING_ZONE_SYNERGY_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneSynergyByPin$ },
        GENDER_PROFILE_BY_PIN_PIE: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingGenderProfileByPinBreakdown$ },
        BUILDING_TIMESPENT_BY_PIN: { dependecies: GraphDependency.BUILDING_TIMESPENT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingTimespentByPinBinData$ },
        PURCHASE_RATE_BY_PIN_TREND: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingPurchaseRateByPinTrend$ },
        BUILDING_MESSENGER_BRAND_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.BUILDING_MESSENGER_BRAND_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingMessengerBrandByPinBreakdownData$ },
        ZONE_AREA_ENTRANCE_EXIT_BY_PIN_TREND: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.zoneAreaEntranceExitByPinTrend$ },
        ZONE_AREA_ENTRANCE_EXIT_TREND: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAreaEntranceExitTrend$ },
        ZONE_AREA_AVG_TIMESPENT_TREND: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAreaAvgTimespentTrend$ },
        ZONE_AREA_GENDER_PIE: { dependecies: GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.allZoneGenderProfileBreakdown$ },
        ZONE_AREA_AGE_PROFILE_BAR: { dependecies: GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.allZoneAgeProfileBreakdown$ },
        ZONE_AREA_PURCHASE_RATE_TREND: { dependecies: GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.allZonePurchaseRateTrend$ },
        ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR_TREND: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.zoneAreaEntranceExitByPinByHourTrend$ },
        ZONE_AREA_ENTRANCE_EXIT_BY_HOUR_TREND: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.allZoneEntranceExitByHour$ },
        ZONE_AREA_BUSIEST_TIME: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.allZoneBusiestTime$ },
        ZONE_AREA_TO_ZONE_SYNERGY_BREAKDOWN: { dependecies: GraphDependency.ALL_ZONE_TO_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.allZoneToZoneSynergyData$ },
        ZONE_TIMESPENT: { dependecies: GraphDependency.ALL_ZONE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.allZoneTimespentData$ },
        ZONE_AREA_BY_PIN_BUSIEST_TIME: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.zoneAreaByPinBusiestTime$ },
        BUILDING_TIMESPENT_BREAKDOWN: { dependecies: GraphDependency.BUILDING_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingTimespentBinData$ },
        BUILDING_ZONE_SYNERGY_BREAKDOWN: { dependecies: GraphDependency.BUILDING_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneSynergy$ },
        ZONE_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.zoneAreaEntranceExitByPinBreakdown$ },
        BUILDING_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaEntranceExitByPinBreakdown$ },
        FLOOR_AREA_ENTRANCE_EXIT_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.FLOOR_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorAreaEntranceExitByPinBreakdown$ },
        STORE_AREA_ENTRANCE_EXIT_TREND: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.storeAreaEntranceExitTrend$ },
        STORE_AREA_PROXIMITY_TREND: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.storeAreaProximityTrend$ },
        STORE_AREA_ENTRANCE_EXIT_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.storeAreaEntranceExitBreakdown$ },
        STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.storeAreaEntranceExitByHourBreakdown$ },
        STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN_2: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR_BREAKDOWN_2, data: (graphDataService: GraphDataService) => graphDataService.storeAreaEntranceExitByHourBreakdown$ },
        STORE_AREA_PROXIMITY_HOUR_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.storeAreaProximityByHourBreakdown$ },
        STORE_AREA_TO_ZONE_SYNERGY_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.storeAreaToZoneSynergyData$ },
        STORE_AREA_TO_ZONE_SYNERGY_RAW_BREAKDOWN: { dependecies: GraphDependency.STORE_AREA_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.storeAreaToZoneSynergyRawData$ },
        BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitData$, },
        FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.entranceExitFloorData$, },
        BUILDING_HOUR_ENTRANCE: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceByHour$, },
        FLOOR_HOUR_ENTRANCE: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR_HOUR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceByHour$, },
        AGE_PROFILE_BREAKDOWN: { dependecies: GraphDependency.VISITOR_PROFILE_TWO_CROSS, data: (graphDataService: GraphDataService) => graphDataService.ageProfileData$, },
        BUILDING_BUSIEST_TIME: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaBusiestTime$ },
        BUILDING_BUSIEST_TIME_AVG: { dependecies: GraphDependency.ENTRANCE_EXIT_HOUR_NUMBER_CARD, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaBusiestTime$ },
        BUILDING_TOTAL_VISIT_DURATION_PERCENTAGE: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentNetShoppingTimeChartPercentageData$ },
        ZONE_TOTAL_VISIT_DURATION_PERCENTAGE: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.zoneNetShoppingHourPercentageData$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_TREND_ALL_AREA: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportTrendAllArea$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_PROX_TREND_ALL_AREA: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportProxTrendAllArea$ },
        BUILDING_TO_BUILDING_SYNERGY_BREAKDOWN: { dependecies: GraphDependency.BUILDING_TO_BUILDING_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.buildingToBuildingSynergy$ },
        BUILDING_TO_BUILDING_SYNERGY_RAW_BREAKDOWN: { dependecies: GraphDependency.BUILDING_TO_BUILDING_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.buildingToBuildingSynergyRaw$ },
        // FLOOR_STORE_AREA_PERCENTAGE: { dependecies: GraphDependency.FLOOR_STORE_AREA, data: (graphDataService: GraphDataService) => graphDataService.floorStoreAreaPercentage$ },
        // FLOOR_STORE_AREA_CATEGORY_PERCENTAGE: { dependecies: GraphDependency.FLOOR_STORE_AREA_CATEGORY, data: (graphDataService: GraphDataService) => graphDataService.floorStoreAreaCategoryPercentage$ },
        FLOOR_STORE_AREA: { dependecies: GraphDependency.FLOOR_STORE_AREA_CATEGORY, data: (graphDataService: GraphDataService) => graphDataService.floorStoreArea$ },
        FLOOR_STORE_AREA_CATEGORY: { dependecies: GraphDependency.FLOOR_STORE_AREA_CATEGORY, data: (graphDataService: GraphDataService) => graphDataService.floorStoreAreaCategory$ },
        VEHICLE_PARKING_MODE_TRANSPORTATION_TREND_ALL_AREA_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_MODE_TRANSPORTATION_ALL_AREA_HOUR, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingModeofTransportByHourTrendAllArea$ },
        BUILDING_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.BUILDING_TRAFFIC_BREAKDOWN, data: (graphDataService: GraphDataService) => graphDataService.buildingTrafficBreakdown$ },
        BUILDING_UNQIUE_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.BUILDING_UNIQUE_TRAFFIC_BREAKDOWN, data: (graphDataService: GraphDataService) => graphDataService.buildingUniqueTrafficBreakdown$ },
        BUILDING_VISITOR_BREAKDOWN: { dependecies: GraphDependency.BUILDING_TRAFFIC_BREAKDOWN, data: (graphDataService: GraphDataService) => graphDataService.buildingVisitorsBreakdown$ },
        FLOOR_AREA_ENTRANCE_EXIT_PIN: { dependecies: GraphDependency.FLOOR_ENTRANCE_EXIT_GATE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceExitPin$ },
        PRED_TRAFFIC_SITE_PURCHASING_POWER: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfilePurchasingPowerBreakdown$ },
        PRED_TRAFFIC_SITE_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfileModeOfTransportBreakdown$ },
        PRED_TRAFFIC_SITE_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfileTopTenCarBrandBreakdown$ },
        PRED_TRAFFIC_SITE_UNIQUE_VISITOR_PURCHASING_POWER: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfileUniqueVisitorPurchasingPowerBreakdown$ },
        PRED_TRAFFIC_SITE_UNIQUE_VISITOR_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfileUniqueVisitorModeOfTransportBreakdown$ },
        PRED_TRAFFIC_SITE_UNIQUE_VISITOR_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.predTrafficSiteProfileUniqueVisitorTopTenCarBrandBreakdown$ },
        TRAFFIC_SITE_ADS_EXPOSURE_HOUR_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_COUNT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteAdsExposureTimebyHour$ },
        TRAFFIC_SITE_PACKAGE_COUNT_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileCountTrend$ },
        TRAFFIC_SITE_PACKAGE_PURCHASING_POWER: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfilePurchasingPowerBreakdown$ },
        TRAFFIC_SITE_PACKAGE_TRANSPORTATION_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileModeOfTransportTrend$ },
        TRAFFIC_SITE_PACKAGE_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileModeOfTransportBreakdown$ },
        TRAFFIC_SITE_PACKAGE_TRANSPORTATION_PUBLIC_PRIVATE_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileModeOfTransportPublicPrivateTrend$ },
        TRAFFIC_SITE_PACKAGE_TRANSPORTATION_PUBLIC_PRIVATE_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_PUBLIC_PRIVATE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileModeOfTransportPublicPrivateBreakdown$ },
        TRAFFIC_SITE_PACKAGE_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileTopTenCarBrandBreakdown$ },
        TRAFFIC_SITE_PACKAGE_PURCHASING_POWER_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfilePurchasingPowerTrend$ },
        TRAFFIC_SITE_PACKAGE_COUNT_HOUR: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileCountByHour$ },
        TRAFFIC_SITE_PACKAGE_CAR_BRAND_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileCarBrandTrend$ },
        TRAFFIC_SITE_PACKAGE_ADS_EXPOSURE_HOUR_TREND: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageAdsExposureTimebyHour$ },
        VEHICLE_PARKING_PROVINCE_UNGROUP_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_ALL_AREA_PROVINCE_UNGROUP, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProvinceEntranceExitTrend$ },
        VEHICLE_PARKING_ALL_AREA_PURCHASING_POWER_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_ALL_AREA_PURCHASING_POWER, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAllAreaPurchasingPowerBreakdown$ },
        VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAllAreaPlateTimespentBinData$ },
        VEHICLE_PARKING_AREA_PLATE_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_ALL_AREA_PLATE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAllAreaPlateTimespentBinSelectedData$ },
        VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT_PERCENTAGE: { dependecies: GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_UNIQUE_AREA_VISIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingToVehicleParkingUniqueAreaVisitData$ },
        VEHICLE_PARKING_PLATE_NUMBER_DEFINITON_PERCENTAGE: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingPlateNumberDefinitonData$ },
        VEHICLE_PARKING_TRAFFIC_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountTrend$ },
        VEHICLE_PARKING_PURCHASING_POWER: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePurchasingPowerBreakdown$ },
        VEHICLE_PARKING_PURCHASING_POWER_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePurchasingPowerTrend$ },
        VEHICLE_PARKING_TRANSPORTATION_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportTrend$ },
        VEHICLE_PARKING_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileModeOfTransportBreakdown$ },
        VEHICLE_PARKING_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileTopTenCarBrandBreakdown$ },
        VEHICLE_PARKING_CAR_BRAND_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCarBrandTrend$ },
        VEHICLE_PARKING_TRAFFIC_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileCountByHour$ },
        VEHICLE_PARKING_BY_PIN_TRAFFIC_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinCountTrend$ },
        VEHICLE_PARKING_BY_PIN_PURCHASING_POWER: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinPurchasingPowerBreakdown$ },
        VEHICLE_PARKING_BY_PIN_PURCHASING_POWER_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinPurchasingPowerTrend$ },
        VEHICLE_PARKING_BY_PIN_TRANSPORTATION_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinModeOfTransportTrend$ },
        VEHICLE_PARKING_BY_PIN_TRANSPORTATION_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinModeOfTransportBreakdown$ },
        VEHICLE_PARKING_BY_PIN_CAR_BRAND_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinTopTenCarBrandBreakdown$ },
        VEHICLE_PARKING_BY_PIN_CAR_BRAND_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinCarBrandTrend$ },
        VEHICLE_PARKING_BY_PIN_TRAFFIC_HOUR: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingVehicleProfileByPinCountByHour$ },
        VEHICLE_PARKING_PROFILE_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileEntranceExit$ },
        VEHICLE_PARKING_PROFILE_BY_PIN_TRAFFIC_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileByPinEntranceExit$ },
        VEHICLE_PARKING_PROFILE_PLATE_NUMBER_DEFINITION_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleProfilePlateDefinitionTrend$ },
        VEHICLE_PARKING_PROFILE_PLATE_NUMBER_DEFINITION_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleProfilePlateDefinitionBreakdown$ },
        VEHICLE_PARKING_ENTRANCE_EXIT_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingEntranceExitBreakdown$ },
        VEHICLE_PARKING_PROFILE_BY_PIN_PLATE_NUMBER_DEFINITION_TREND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleProfileByPinPlateDefinitionTrend$ },
        VEHICLE_PARKING_PROFILE_BY_PIN_PLATE_NUMBER_DEFINITION_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.vehicleProfileByPinPlateDefinitionBreakdown$ },
        ZONE_TIMESPENT_BUCKET: { dependecies: GraphDependency.BUILDING_FLOOR_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorTimespentData$ },
        ZONE_VISITOR_TIMESPENT: { dependecies: GraphDependency.BUILDING_FLOOR_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorVisitorPercentage$ },
        ZONE_VISITOR_TRAFFIC_TIMESPENT: { dependecies: GraphDependency.BUILDING_FLOOR_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorVisitor$ },
        STORE_FRONT_BACK_PROX_BREAKDOWN_PERCENTAGE: { dependecies: GraphDependency.STORE_PROXIMITY_FLOOR_TRAFFIC, data: (graphDataService: GraphDataService) => graphDataService.storeFrontBackTrafficData$ },
        BUILDING_ENTRANCE_EXIT_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitByPinData$ },
        ZONE_ENTRANCE_EXIT_BY_PIN_BREAKDOWN: { dependecies: GraphDependency.ENTRANCE_EXIT_ZONE_PIN_HOUR, data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceExitByPinData$ },
        EVENT_TRAFFIC_TREND: { dependecies: EventDependency.EVENT_TRAFFIC, data: (eventDataService: EventDataService) => eventDataService.eventTrafficTrend$ },
        VEHICLE_PARKING_FREQ_VISIT: { dependecies: GraphDependency.VEHICLE_PARKING_FREQ_VISIT, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingFrequencyOfVisitData$ },
        VEHICLE_PARKING_REPEATED_VISITORS: { dependecies: GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRepeatedVisitorsData$ },
        OVERALL_VEHICLE_PARKING_PURCHASING_POWER: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfilePurchasingPowerBreakdown$ },
        OVERALL_VEHICLE_PARKING_CAR_BRAND: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileTopTenCarBrandBreakdown$ },
        BUILDING_FLOOR_ENTRANCE_EXIT_GROUPBY_PIN: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorEntranceExitGroupbyPin$ },
        BUILDING_FLOOR_ENTRANCE_EXIT_GROUPBY_PIN_AVG: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_ALL_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingFloorEntranceExitGroupbyPinAvgPerDay$ },
        BRAND_TRAFFIC: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.brandEntranceExitBreakdown$ },
        BUILDING_ETHNICITY_PROFILE_BY_PIN: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingEthnicityProfileByPinBreakdown$ },
        BUILDING_ETHNICITY_PURCHASE_RATE_BY_PIN: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingEthnicityPurchasingByPinBreakdown$ },
        BUILDING_CUSTOM_SEGMENT_PURCHASE_RATE_BY_PIN: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_TWO_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingCustomSegmentPurchasingByPinBreakdown$ },
        STORE_CUSTOM_SEGMENT_PURCHASE_RATE: { dependecies: GraphDependency.STORE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.storeCustomSegmentPurchasingBreakdown$ },
        STORE_ETHNICITY_PROFILE: { dependecies: GraphDependency.STORE_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.storeEthnicityProfileBreakdown$ },
        VEHICLE_PARKING_VEHCILE_PROFILE_ENTRANCE_EXIT_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingVehicleProfileEntranceExitBreakdown$ },
        VEHICLE_PARKING_AREA_PURCHASING_POWER_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAreaPurchasingPowerBreakdown$ },
        VEHICLE_PARKING_AREA_VEHICLE_TYPE_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAreaVehicleTypeBreakdown$ },
        VEHICLE_PARKING_AREA_TOP10_CAR_BRANDS_BREAKDOWN: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAreaCarBrandsBreakdown$ },
        VEHICLE_PARKING_FREQ_VISIT_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_FREQ_VISIT_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingProfileFrequencyOfVisitData$ },
        VEHICLE_PARKING_AREA_REPEATED_VISITORS: { dependecies: GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingAreaRepeatedVisitorsData$ },
        VEHICLE_PARKING_REPEATED_VISITORS_PROFILE_PERCENTAGE: { dependecies: GraphDependency.VEHICLE_PARKING_REPEATED_VISITORS_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRepeatedVisitorsProfilePercentageData$ },
        VEHICLE_PARKING_PLATE_TIMESPENT_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_TIMESPENT_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingPlateTimespentBinProfileData$ },
    };

    public static readonly selectableNumberCardDataMapper: { [key in SelectableNumberCardDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        MALL_TRAFFIC: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountData$ },
        MALL_TRAFFIC_AVG: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingEntranceExitAvgByDayData$ },
        MALL_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllBuildingAvgWeekdayEntranceExit$ },
        MALL_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllBuildingAvgWeekendEntranceExit$ },
        // MALL_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAvgWeekdayEntranceExit$ },
        // MALL_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAvgWeekendEntranceExit$ },
        MALL_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.currentTotalTransportationData$ },
        STAFF_TRAFFIC: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentStaffProfileData$ },
        COURIER_TRAFFIC: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentMessengerProfileData$ },
        MESSENGER_BRAND_TRAFFIC: { dependecies: GraphDependency.MESSENGER_BRAND, data: (graphDataService: GraphDataService) => graphDataService.currentTotalMessengerData$ },
        MALL_AVG_DURATION_OF_VISIT: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAverageTimeSpentData$ },
        MALL_TOTAL_VISIT_DURATION: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentNetVisitorHourData$ },
        BUILDING_TOTAL_VISIT_DURATION: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingNetShoppingHourData$ },
        MALL_SHOPPING_BAG_RATE: { dependecies: GraphDependency.PURCHASE_RATE, data: (graphDataService: GraphDataService) => graphDataService.currentpurchaseRateData$ },
        MALL_MASK_USE: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentMaskCount$ },
        CUSTOMER_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.currentTotalCustomerTransportationData$ },
        STAFF_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.currentTotalStaffTransportationData$ },
        MALL_AVG_WEEKDAY_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.allVehicleTrafficWeekdayLast7daysData$ },
        MALL_AVG_WEEKEND_VEHICLE_TRAFFIC: { dependecies: GraphDependency.MODE_OF_TRANSPORTATION, data: (graphDataService: GraphDataService) => graphDataService.allVehicleTrafficWeekendLast7daysData$ },
        MALL_VEHICLE_UNIQUE_VISITORS: { dependecies: GraphDependency.VEHICLE_UNIQUE_VISITORS, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleUniqueVisitors$ },
        TRAFFIC_SITE_COUNT: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteProfileCount$ },
        TRAFFIC_ADS_FREQUENCY: { dependecies: GraphDependency.TRAFFIC_SITE_ADS_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.trafficAdsFrequency$ },
        TRAFFIC_SITE_UNIQUE_VISITOR: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteProfileUniqueVisitorCount$ },
        TRAFFIC_SITE_AVG_WEEKDAY_COUNT: { dependecies: [GraphDependency.TRAFFIC_SITE_COUNT, GraphDependency.TRAFFIC_SITE_AVG_COUNT_BY_DAY_TYPE], data: (graphDataService: GraphDataService) => [graphDataService.currentTrafficSiteAvgWeekdayLast7DaysCount$, graphDataService.trafficSiteAvgWeekdayCount$] },
        TRAFFIC_SITE_AVG_WEEKEND_COUNT: { dependecies: [GraphDependency.TRAFFIC_SITE_COUNT, GraphDependency.TRAFFIC_SITE_AVG_COUNT_BY_DAY_TYPE], data: (graphDataService: GraphDataService) => [graphDataService.currentTrafficSiteAvgWeekendLast7DaysCount$, graphDataService.trafficSiteAvgWeekendCount$] },
        TRAFFIC_SITE_PEAK_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePeakTime$ },
        TRAFFIC_SITE_OFF_PEAK_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileOffPeakTime$ },
        TRAFFIC_SITE_AVG_VISITOR: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteAvgVisitorCount$ },
        VEHICLE_PARKING_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingEntranceExit$ },
        VEHICLE_PARKING_TRAFFIC_P2: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P2, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPKP2EntranceExit$ },
        VEHICLE_PARKING_TRAFFIC_P3: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P3, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPKP3EntranceExit$ },
        VEHICLE_PARKING_PROXIMITY: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingProximity$ },
        VEHICLE_PARKING_PROXIMITY_P2: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P2, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPKP2Proximity$ },
        VEHICLE_PARKING_CONVERSION_RATE: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingConversionRate$ },
        VEHICLE_PARKING_CONVERSION_RATE_P2: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT_P2, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPKP2ConversionRate$ },
        // MOCK_STORE_FOOT_TRAFFIC: { dependecies: GraphDependency.MOCK_STORE_COUNT, data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreCount$ },
        MOCK_STORE_FOOT_TRAFFIC: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaImpression$ },
        // MOCK_STORE_AVG_WEEKDAY_FOOT_TRAFFIC: { dependecies: [GraphDependency.MOCK_STORE_COUNT, GraphDependency.MOCK_STORE_COUNT_AVG_BY_DAY_TYPE], data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreCountAverageWeekday$ },
        MOCK_STORE_AVG_WEEKDAY_FOOT_TRAFFIC: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaImpressionAvgWeekDayLast7Day$ },
        MOCK_STORE_AVG_WEEKEND_FOOT_TRAFFIC: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaImpressionAvgWeekEndLast7Day$ },
        // MOCK_STORE_VISITOR: { dependecies: GraphDependency.MOCK_STORE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreVisitor$ }, 
        MOCK_STORE_VISITOR: { dependecies: GraphDependency.LIDAR_AREA_REACH, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaEngagementCount$ },
        // MOCK_STORE_VISITOR_AVG_TIME_SPENT: { dependecies: GraphDependency.MOCK_STORE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreVisitorAvgTimeSpent$ }, 
        MOCK_STORE_VISITOR_AVG_TIME_SPENT: { dependecies: GraphDependency.LIDAR_AREA_AVG_ENGAGEMENT_MINUTES, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaAvgEngagementMinutes$ },
        // MOCK_STORE_AVG_WEEKDAY_VISITOR: { dependecies: [GraphDependency.MOCK_STORE_VISITOR, GraphDependency.MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE], data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreVisitorAverageWeekday$ }, 
        // MOCK_STORE_AVG_WEEKEND_VISITOR: { dependecies: [GraphDependency.MOCK_STORE_VISITOR, GraphDependency.MOCK_STORE_VISITOR_AVG_BY_DAY_TYPE], data: (graphDataService: GraphDataService) => graphDataService.currentMockStoreVisitorAverageWeekend$ }, 
        MOCK_STORE_AVG_WEEKDAY_VISITOR: { dependecies: GraphDependency.LIDAR_AREA_REACH, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaEngagementCountAvgWeekDayLast7Day$ },
        MOCK_STORE_AVG_WEEKEND_VISITOR: { dependecies: GraphDependency.LIDAR_AREA_REACH, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaEngagementCountAvgWeekDayLast7Day$ },
        // MOCK_STORE_CONVERSION_RATE: { dependecies: GraphDependency.MOCK_STORE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.currentMockConversionRate$ },  
        MOCK_STORE_CONVERSION_RATE: { dependecies: GraphDependency.LIDAR_AREA_CONVERSION_RATE, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaConversionRate$ },
        // MOCK_STORE_VISITOR_PEAK_TIME: { dependecies: GraphDependency.MOCK_STORE_VISITOR_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.mockStoreVisitorPeakTime$ }, 
        MOCK_STORE_VISITOR_PEAK_TIME: { dependecies: GraphDependency.LIDAR_AREA_REACH_BY_HOUR, data: (graphDataService: GraphDataService) => graphDataService.lidarAreaEngagementCountPeakTime$ },
        // MOCK_ADS_VISITOR_PROFILE_COUNT: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentMockAdsVisitorProfile$ }, 
        MOCK_ADS_VISITOR_PROFILE_COUNT: { dependecies: GraphDependency.LIDAR_AREA_IMPRESSION, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaImpression$ },
        // MOCK_ADS_AVG_TIME_EXPOSE: { dependecies: GraphDependency.MOCK_ADS_VISITOR_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentMockAdsAvgTimeExpose$}, 
        MOCK_ADS_AVG_TIME_EXPOSE: { dependecies: GraphDependency.LIDAR_AREA_AVG_ENGAGEMENT_MINUTES, data: (graphDataService: GraphDataService) => graphDataService.currentLidarAreaAvgEngagementMinutes$ },
        ZONE_TRAFFIC_BY_PIN: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.currentZoneAreaEntranceExitByPin$ },
        ZONE_AVG_WEEKDAY_TRAFFIC_BY_PIN: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentZoneAvgWeekdayLast7DaysEntranceExitByPin$ },
        ZONE_AVG_WEEKEND_TRAFFIC_BY_PIN: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentZoneAvgWeekendLast7DaysEntranceExitByPin$ },
        ZONE_AVG_TIMESPENT_BY_PIN: { dependecies: GraphDependency.ZONE_AREA_ENTRANCE_EXIT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.currentZoneAreaAvgTimespentByPin$ },
        ZONE_PURCHASE_RATE_BY_PIN: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingPurchaseRateByPin$ },
        ALL_ZONE_TRAFFIC: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAreaEntranceExit$ },
        ALL_ZONE_AREA_AVG_WEEKDAY: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAvgWeekdayLast7DaysEntranceExit$ },
        ALL_ZONE_AREA_AVG_WEEKEND: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAvgWeekendLast7DaysEntranceExit$ },
        ALL_ZONE_AVG_TIMESPENT: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAreaAvgTimespent$ },
        ALL_ZONE_AVG_TIMESPENT_REID: { dependecies: GraphDependency.ALL_ZONE_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.allZoneAvgTimespentDataReID$ },
        ALL_ZONE_PURCHASE_RATE: { dependecies: GraphDependency.ALL_ZONE_AREA_VISITOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.currentallZonePurchaseRate$ },
        BUILDING_AVG_TIMESPENT: { dependecies: GraphDependency.BUILDING_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingAvgTimespentData$ },
        BUILDING_AVG_TIMESPENT_BY_PIN: { dependecies: GraphDependency.BUILDING_TIMESPENT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingAvgTimespentByPinData$ },
        BUILDING_AVG_TIMESPENT_REID: { dependecies: GraphDependency.BUILDING_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.buildingAvgTimespentData$ },
        BUILDING_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllBuildingAvgWeekdayEntranceExit$ },
        BUILDING_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.BUILDING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentAllBuildingAvgWeekendEntranceExit$ },
        STORE_AREA_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentStoreAreaEntranceExitAvgWeekday$ },
        STORE_AREA_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentStoreAreaEntranceExitAvgWeekend$ },
        STORE_AREA_TRAFFIC: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentStoreAreaEntranceExit$ },
        STORE_AREA_AVG_TIMESPENT: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentStoreAreaAvgTimespent$ },
        STORE_AREA_BUSIEST_TIME: { dependecies: GraphDependency.STORE_AREA_ENTRANCE_EXIT_HOUR, data: (graphDataService: GraphDataService) => graphDataService.storeAreaBusiestTime$ },
        BUILDING_TRAFFIC: { dependecies: GraphDependency.ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountData$ },
        FLOOR_TRAFFIC: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingHeadCountFloorData$ },
        FLOOR_AVG_TIMESPENT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.currentAvgTimeSpentFloorData$ },
        FLOOR_NET_SHOPPING_TIME: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.currentNetShoppingTimeFloorData$ },
        ZONE_AREA_TRAFFIC: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAreaEntranceExit$ },
        BUILDING_AREA_STUDENT_PROFILE_TRAFFIC: { dependecies: GraphDependency.BUILDING_AREA_VISTIOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAreaStudentProfileData$ },
        BUILDING_AREA_MASK_PROFILE_RATE: { dependecies: GraphDependency.BUILDING_AREA_VISTIOR_PROFILE_CROSS_LEVEL_TWO, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAreaMaskProfileData$ },
        VEHICLE_PARKING_AREA_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAreaVehicleParkingEntranceExit$ },
        PRED_TRAFFIC_SITE_COUNT: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.predCurrentTrafficSiteProfileCountbyRange$ },
        PRED_TRAFFIC_SITE_ADS_EXPOSURE: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_COUNT, data: (graphDataService: GraphDataService) => graphDataService.predCurrentTrafficSiteAdsExposurebyRange$ },
        PRED_TRAFFIC_SITE_UNIQUE_VISITOR: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.predCurrentTrafficSiteProfileUniqueVisitorCountbyRange$ },
        PRED_TRAFFIC_SITE_AVG_VISITOR: { dependecies: GraphDependency.PREDICTIVE_TRAFFIC_SITE_VEHICLE_PROFILE_UNIQUE_VISITOR, data: (graphDataService: GraphDataService) => graphDataService.predCurrentTrafficSiteAvgVisitorCountbyRange$ },
        TRAFFIC_SITE_PACKAGE_COUNT: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSitePackageProfileCount$ },
        TRAFFIC_SITE_PACKAGE_PEAK_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfilePeakTime$ },
        TRAFFIC_SITE_PACKAGE_OFF_PEAK_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfileOffPeakTime$ },
        TRAFFIC_SITE_EXPOSURE_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_COUNT, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteExposureTimeCount$ },
        TRAFFIC_SITE_PACKAGE_EXPOSURE_TIME: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_COUNT, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSitePackageExposureTimeCount$ },
        CURRENT_VEHICLE_PARKING_AVG_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingAvgTimespent$ },
        VEHICLE_PARKING_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingEntranceExitAvgWeekday$ },
        VEHICLE_PARKING_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingEntranceExitAvgWeekend$ },
        VEHICLE_PARKING_AERA_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingAreaEntranceExitAvgWeekday$ },
        VEHICLE_PARKING_AREA_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.VEHCILE_PARKING_ENTRANCE_EXIT_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingAreaEntranceExitAvgWeekend$ },
        VEHICLE_PARKING_VEHICLE_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteProfileCount$ },
        VEHICLE_PARKING_PEAK_TIME: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePeakTime$ },
        VEHICLE_PARKING_OFF_PEAK_TIME: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileOffPeakTime$ },
        VEHICLE_PARKING_BY_PIN_VEHICLE_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteProfileCount$ },
        VEHICLE_PARKING_BY_PIN_PEAK_TIME: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfilePeakTime$ },
        VEHICLE_PARKING_BY_PIN_OFF_PEAK_TIME: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSiteProfileOffPeakTime$ },
        VEHICLE_PARKING_PROFILE_AVG_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentTrafficSiteProfileAvgTimespent$ },
        VEHICLE_PARKING_PROFILE_BY_PIN_AVG_TIMESPENT: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_PIN, data: (graphDataService: GraphDataService) => graphDataService.currentvehicleParkingProfileByPinAvgTimespent$ },
        VEHICLE_PARKING_PLATE_NUMBER_DEFINITION_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPlateNumberDefinitonData$ },
        ZONE_AVG_TIMESPENT_UPPER5: { dependecies: GraphDependency.BUILDING_FLOOR_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingFloorAvgTimespentUpper5$ },
        ZONE_VISITOR_AVG_TIMESPENT_LOWER5: { dependecies: GraphDependency.BUILDING_FLOOR_TIMESPENT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingFloorVisitorAvgTimespentLower5$ },
        STORE_FRONT_PROX_TRAFFIC: { dependecies: GraphDependency.STORE_PROXIMITY_FLOOR_TRAFFIC, data: (graphDataService: GraphDataService) => graphDataService.currentStoreFrontTrafficData$ },
        STORE_BACK_PROX_TRAFFIC: { dependecies: GraphDependency.STORE_PROXIMITY_FLOOR_TRAFFIC, data: (graphDataService: GraphDataService) => graphDataService.currentStoreBackTrafficData$ },
        STORE_EST_PROX_TRAFFIC_RANGE: { dependecies: GraphDependency.STORE_PROXIMITY_FLOOR_TRAFFIC, data: (graphDataService: GraphDataService) => graphDataService.storeEstProxTrafficRangeData$ },
        ZONE_CONV_RATE: { dependecies: GraphDependency.ALL_ZONE_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentAllZoneAreaConversionRate$ },
        VISITOR_TRAFFIC: { dependecies: GraphDependency.VISITOR_PROFILE_SIMPLE_CROSS, data: (graphDataService: GraphDataService) => graphDataService.currentVisitorProfileData$ },
        TRAFFIC_SITE_PACKAGE_PEAK_TIME_2: { dependecies: GraphDependency.TRAFFIC_SITE_PACKAGE_VEHICLE_PROFILE_HOUR, data: (graphDataService: GraphDataService) => graphDataService.trafficSitePackageProfilePeakTime$ },
        STAFF_TRAFFIC_COUNT: { dependecies: GraphDependency.STAFF_TRAFFIC_COUNT, data: (graphDataService: GraphDataService) => graphDataService.staffTrafficCount$ },
        STAFF_TRAFFIC_COUNT_2: { dependecies: GraphDependency.STAFF_TRAFFIC_COUNT_2, data: (graphDataService: GraphDataService) => graphDataService.staffTrafficCount_2$ },
        MALE_TRAFFIC_COUNT: { dependecies: GraphDependency.MALE_TRAFFIC_COUNT, data: (graphDataService: GraphDataService) => graphDataService.maleTrafficCount },
        FEMALE_TRAFFIC_COUNT: { dependecies: GraphDependency.FEMALE_TRAFFIC_COUNT, data: (graphDataService: GraphDataService) => graphDataService.femaleTrafficCount },
        STORE_SHOPPING_BAG_RATE: { dependecies: GraphDependency.STORE_SHOPPING_BAG_RATE, data: (graphDataService: GraphDataService) => graphDataService.storePurchaseRateData$ },
        MALE_COUNT_BY_PIN: { dependecies: GraphDependency.MALE_COUNT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.maleTrafficCount },
        FEMALE_COUNT_BY_PIN: { dependecies: GraphDependency.FEMALE_COUNT_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.femaleTrafficCount },
        MALE_BUILDING_COUNT: { dependecies: GraphDependency.MALE_BUILDING_COUNT, data: (graphDataService: GraphDataService) => graphDataService.maleBuildingTrafficCount$ },
        FEMALE_BUILDING_COUNT: { dependecies: GraphDependency.FEMALE_BUILDING_COUNT, data: (graphDataService: GraphDataService) => graphDataService.femaleBuildingTrafficCount$ },
        VEHICLE_PARKING_NEW_VISITOR: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingNewVisitorCount$ },
        VEHICLE_PARKING_LOSS_VISITOR: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingLossVisitorCount$ },
        VEHICLE_PARKING_REGULAR_VISITOR: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRegularVisitorCount$ },
        VEHICLE_PARKING_CHURN_VISITOR: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingChurnVisitorCount$ },
        VEHICLE_PARKING_VEHICLE_PROFILE_AVG_WEEKDAY_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingVehicleProfileEntranceExitAvgWeekday$ },
        VEHICLE_PARKING_VEHICLE_PROFILE_AVG_WEEKEND_TRAFFIC: { dependecies: GraphDependency.VEHICLE_PARKING_VEHICLE_PROFILE_AVG_DAY_TYPE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingVehicleProfileEntranceExitAvgWeekend$ },
        BUILDING_UNIQUE_TRAFFIC: { dependecies: GraphDependency.BUILDING_UNIQUE_TRAFFIC_BREAKDOWN, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingUniqueTrafficBreakdown$ },
        VEHICLE_PARKING_NEW_VISITOR_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingNewVisitorProfileCount$ },
        VEHICLE_PARKING_LOSS_VISITOR_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingLossVisitorProfileCount$ },
        VEHICLE_PARKING_REGULAR_VISITOR_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRegularVisitorProfileCount$ },
        VEHICLE_PARKING_CHURN_VISITOR_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingChurnVisitorProfileCount$ },
        VEHICLE_PARKING_TIMEPSENT_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_TIMESPENT_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.currentVehicleParkingPlateTimespentProfileData$ },
        BUILDING_AREA_TRAFFIC: { dependecies: GraphDependency.BUILDING_AREA_ENTRANCE_EXIT, data: (graphDataService: GraphDataService) => graphDataService.currentBuildingAreaEntranceExitData$ },
    };

    public static readonly selectableDividerSelector: { [key in SelectableDividerSelectorDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        VEHICLE_PROFILE: { dependecies: GraphDependency.TRAFFIC_SITE_VEHICLE_PROFILE, data: (graphDataService: GraphDataService) => [graphDataService.unfilteredVehicleProfileData$, graphDataService.selectedVehicleProfile$] },
    };

    public static readonly selectableSankeyDataMapper: { [key in SelectableSankeyDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        FLOW_BUILDING_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, data: (graphDataService: GraphDataService) => graphDataService.buildingEntranceExitFlowData$ },
        FLOW_FLOOR_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_FLOOR, data: (graphDataService: GraphDataService) => graphDataService.floorEntranceExitFlowData$ },
        FLOW_ZONE_ENTRANCE_EXIT: { dependecies: GraphDependency.ENTRANCE_EXIT_FLOW_ZONE, data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceExitFlowData$ },
        BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN: { dependecies: GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_ONE_DIRECTION_UNMERGED_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneTrafficFlowOneDirectionUnmergedByPin$ },
        BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN: { dependecies: GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_UNMERGED_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneTrafficFlowUnmergedByPin$ },
        BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN: { dependecies: GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneTrafficFlowByPin$ },
        BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN: { dependecies: GraphDependency.BUILDING_ZONE_TRAFFIC_FLOW_NO_LOOKBACK_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneTrafficFlowNoLookbackByPin$ },
        STORE_ZONE_TRAFFIC_FLOW: { dependecies: GraphDependency.STORE_AREA_ZONE_TRAFFIC_FLOW, data: (graphDataService: GraphDataService) => graphDataService.storeAreaZoneTrafficFlow$ },
        BUILDING_ZONE_TRAFFIC_FLOW: { dependecies: GraphDependency.BUILDING_AREA_ZONE_TRAFFIC_FLOW, data: (graphDataService: GraphDataService) => graphDataService.buildingAreaZoneTrafficFlow$ },
        // DYNAMIC_FLOW_ENTRANCE_EXIT: { dependecies: [GraphDependency.ENTRANCE_EXIT_FLOW_BUILDING, GraphDependency.ENTRANCE_EXIT_FLOW_FLOOR, GraphDependency.ENTRANCE_EXIT_FLOW_ZONE], data: (graphDataService: GraphDataService) => graphDataService.zoneEntranceExitFlowData$ },
    };

    public static readonly selectablePieDataMapper: { [key in SelectablePieDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        GENDER_PROFILE_BY_PIN_PIE: { dependecies: GraphDependency.BUILDING_VISITOR_PROFILE_CROSS_LEVEL_BY_ONE_BY_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingGenderProfileByPinBreakdown$ },
    };

    public static readonly selectableHeatmapDataMapper: { [key in SelectableHeatmapDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => BehaviorSubject<any> | BehaviorSubject<any>[] } } = {
        ZONE_TO_ZONE_SYNERGY: { dependecies: GraphDependency.ALL_ZONE_TO_ZONE_SYNERGY, data: (graphDataService: GraphDataService) => graphDataService.allZoneToZoneSynergyData$ },
        PIN_TO_ZONE_SYNERGY: { dependecies: GraphDependency.BUILDING_ZONE_SYNERGY_ALL_PIN, data: (graphDataService: GraphDataService) => graphDataService.buildingZoneSynergyAllPin$ },
        PARKING_RETENTION: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRetentionData$ },
        VEHICLE_PARKING_TO_PARKING_SYNERGY: { dependecies: GraphDependency.VEHICLE_PARKING_TO_VEHICLE_PARKING_SYNERGY_ALL_AREA, data: (graphDataService: GraphDataService) => graphDataService.allZoneToZoneSynergyData$ },
        VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE: { dependecies: GraphDependency.VEHICLE_PARKING_RECENCY_FREQUENCY_PROFILE, data: (graphDataService: GraphDataService) => graphDataService.vehicleParkingRetentionProfileData$ },
    };

    public static readonly SelectableDataMapper: {
        [key in SelectableDataName]: { dependecies: BaseDataDependency | BaseDataDependency[]; data: (baseDataService: BaseDataServiceInstance) => (BehaviorSubject<any> | BehaviorSubject<any>[]) } }
        = {
            ...SelectableData.SelectableLineDataMapper, ...SelectableData.SelectableBarDataMapper, ...SelectableData.selectableGoalDataMapper, ...SelectableData.selectableReportDataMapper,
            ...SelectableData.selectableCustomDataMapper, ...SelectableData.selectableNumberCardDataMapper, ...SelectableData.selectableDividerSelector, ...SelectableData.selectableSankeyDataMapper,
            ...SelectableData.selectablePieDataMapper, ...SelectableData.selectableHeatmapDataMapper
        };
}
