import { GraphResolverBase } from './GraphResolverBase';
import { ComponentFactory, ViewContainerRef } from '@angular/core';
import { ConfigDataService } from '../services/config-data.service';
import { GraphDataService } from '../services/graph-data-service.service';
import { ViewPeriodService } from '../services/view-period.service';
import { BehaviorSubject, combineLatest, distinctUntilChanged, Subscription } from 'rxjs';
import { DynamicLineChartWrapperComponent } from '../shared-components/dynamic/dynamic-line-chart-wrapper/dynamic-line-chart-wrapper.component';
import { GenericLineChartData } from '../objects/chart';
import { CHART_PRIMARY_COLOR, PIE_CHART_COLOR_LIST } from '../configs/color';
import { Label } from 'ng2-charts';
import { PopoverController } from '@ionic/angular';
import * as moment from 'moment';
import { CustomLegendConfig, DynamicGraphAdditionalInput, GraphInfoPopover, GraphInfoPopoverByPeriod } from '../objects/config';
import { getAdditionalInput } from '../helpers/DynamicGraphHelper';
import { accessDepthData, getDepthData, isShowGraph } from '../helpers/util';
import { SelectableLineChartDataName } from '../objects/selectableData';
import { CustomTextTooltipsComponent } from '../pages/home/general/custom-text-tooltips/custom-text-tooltips.component';
import { AuthenticationService } from '../services/authentication.service';
import { generateNestedData } from '../helpers/mock-data-generator';

const COLOR_LIST = [...PIE_CHART_COLOR_LIST];
COLOR_LIST.unshift(CHART_PRIMARY_COLOR);

export class DynamicLineChartSelectableResolver extends GraphResolverBase {

    public async createComponent(
        componentFactory: ComponentFactory<unknown>,
        additionalInput: string | DynamicGraphAdditionalInput | undefined,
        configDataService: ConfigDataService,
        graphDataService: GraphDataService,
        popoverController: PopoverController,
        viewPeriodService: ViewPeriodService,
        viewContainerRef: ViewContainerRef,
        subscription: Subscription,
        authenicationService: AuthenticationService,
    ) {
        // await configDataService.loadAppConfig();
        // const channel = (getAdditionalInput(additionalInput, 'channel') || 'auto') as 'entrance' | 'exit' | 'auto';
        const graphState = (getAdditionalInput(additionalInput, 'graphState') || 'ENABLE') as 'ENABLE' | 'DISABLE' | 'LOCK' | 'LOCK_COND';
        const chartTitleKey = (getAdditionalInput(additionalInput, 'chartTitleKey')) as string;
        const lineChartLebelMode = (getAdditionalInput(additionalInput, 'lineChartLebel') || 'DAY_LIST') as 'TIME_LIST' | 'DAY_LIST' | 'VEHICLE_TIME_LIST' | 'FIXED_TIME_LIST';
        const lineChartDataSelector = (getAdditionalInput(additionalInput, 'dataSelector') || {}) as { [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
        const lineChartCustomLegendConfig = (getAdditionalInput(additionalInput, 'customLegendConfig') || {}) as CustomLegendConfig;
        const graphInfoPopover = (getAdditionalInput(additionalInput, 'graphInfoPopover')) as GraphInfoPopover;
        const useOnSpecificUser = (getAdditionalInput(additionalInput, 'useOnSpecificUser') || false) as boolean;
        const displayOnPeriodType = (getAdditionalInput(additionalInput, 'displayOnPeriodType')) as { live: boolean; day: boolean; week: boolean; month: boolean };
        const onSpecificOrganization = (getAdditionalInput(additionalInput, 'onSpecificOrganization')) as string;
        const excludedDirectory = (getAdditionalInput(additionalInput, 'excludedDirectory')) as 'BUILDING' | 'FLOOR' | 'ZONE' | 'FLOOR_AND_ZONE' | 'BUILDING_AND_FLOOR';
        const showAveragePerDay = (getAdditionalInput(additionalInput, 'showAveragePerDay') || false) as boolean;
        const filteredMultiple = (getAdditionalInput(additionalInput, 'filteredMultiple')) as boolean;
        const filteredGroupData = (getAdditionalInput(additionalInput, 'filteredGroupData') || false) as boolean;
        const graphInfoPopoverByPeriod = (getAdditionalInput(additionalInput, 'graphInfoPopoverByPeriod')) as GraphInfoPopoverByPeriod;
        const updateTextByPeriod = (getAdditionalInput(additionalInput, 'updateTextByPeriod') || false) as boolean;
        const compareGraph = (getAdditionalInput(additionalInput, 'compareGraph')) as boolean;
        const customTooltipText = (getAdditionalInput(additionalInput, 'customTooltipText')) as boolean;


        const isMockData = (getAdditionalInput(additionalInput, 'isMockData')) as boolean;
        const dataPredictBehaviorSubjects$: BehaviorSubject<unknown>[] = [];
        let dataBehaviorSubjects$: BehaviorSubject<unknown>[] = [];
        if (!isMockData) {
            dataBehaviorSubjects$ = (Object.values(lineChartDataSelector).map((selectorDetail) => {
                const selectedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph(selectorDetail.name, graphDataService);
                if (selectorDetail.name.includes('ENTRANCE_EXIT')) {
                    let selectedPredictedResolvedDetail: any;
                    switch (selectorDetail.name) {
                        case 'BUILDING_ENTRANCE_EXIT':
                            selectedPredictedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph('PREDICTION_BUILDING_ENTRANCE_EXIT', graphDataService);
                            graphDataService.baseGraphData.addDependency(selectedPredictedResolvedDetail.dependencies);
                            dataPredictBehaviorSubjects$.push(selectedPredictedResolvedDetail.data);
                            break;
                        case 'BUILDING_ENTRANCE_EXIT_AVG':
                            selectedPredictedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph('PREDICTION_BUILDING_ENTRANCE_EXIT_AVG', graphDataService);
                            graphDataService.baseGraphData.addDependency(selectedPredictedResolvedDetail.dependencies);
                            dataPredictBehaviorSubjects$.push(selectedPredictedResolvedDetail.data);
                            break;
                        case 'FLOOR_ENTRANCE_EXIT':
                            selectedPredictedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph('PREDICTION_FLOOR_ENTRANCE_EXIT', graphDataService);
                            graphDataService.baseGraphData.addDependency(selectedPredictedResolvedDetail.dependencies);
                            dataPredictBehaviorSubjects$.push(selectedPredictedResolvedDetail.data);
                            break;
                        case 'ZONE_ENTRANCE_EXIT':
                            selectedPredictedResolvedDetail = graphDataService.baseGraphData.getSelectedGraph('PREDICTION_ZONE_ENTRANCE_EXIT', graphDataService);
                            graphDataService.baseGraphData.addDependency(selectedPredictedResolvedDetail.dependencies);
                            dataPredictBehaviorSubjects$.push(selectedPredictedResolvedDetail.data);
                            break;
                        default:
                            break;
                    }
                } else {
                    dataPredictBehaviorSubjects$.push(selectedResolvedDetail.data);
                }
                graphDataService.baseGraphData.addDependency(selectedResolvedDetail.dependencies);
                return selectedResolvedDetail.data;
            }));
        }
        //#region defined data access function
        type EntraceExitData = { entrance: number[]; exit: number[] };
        const fillData: number[] = Array.from({ length: 7 }).map(() => null);
        const getEntraceExitData = (isEntrance: boolean, data: { entrance: number[]; exit: number[] }) => isEntrance ? data.entrance : data.exit;
        const getBuildingFloorData = (
            isEntrance: boolean,
            data: number[] | { [buildingName: string]: any | { [floorName: string]: any } | { [floorName: string]: { [zoneName: string]: any } } },
            buildingName?: string,
            floorName?: string,
            depthZoneName?: string,
        ) => {
            let retData: unknown;
            if (buildingName === undefined && floorName === undefined && depthZoneName === undefined) { // args.length = 0
                retData = data || fillData;
            } else {
                retData = accessDepthData<unknown>(data as any, buildingName, floorName, depthZoneName, fillData);
            }
            if (Object.keys(retData).includes('entrance')) {
                return getEntraceExitData(isEntrance, retData as EntraceExitData);
            }
            return retData as number[];
        };

        //#endregion
        const selectedLine$ = new BehaviorSubject<{ [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } }>(lineChartDataSelector);
        const initialTodayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
        const initialColor: string[] = [];
        const getColor = (lineName: string) => (((configDataService.GRAPH_CONFIG.INTERACTABLE_LINE_CHART || {})[chartTitleKey] || {})[lineName] || {}) as { color: string; backgroundColor?: string };

        const numberData$ = new BehaviorSubject<number>(0);
        const label$ = new BehaviorSubject<string>(null);
        const labelHeader$ = new BehaviorSubject<string>(lineChartCustomLegendConfig?.label ? lineChartCustomLegendConfig.label.header : null);
        const labelStyle: { fontSize: string; fontWeight: string; color: string } = { fontSize: '1rem', fontWeight: '500', color: '#5BBF93' };
        const labelIcon = lineChartCustomLegendConfig?.iconName ? lineChartCustomLegendConfig.iconName : 'people';
        const timeLabelList = lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST : lineChartLebelMode === 'VEHICLE_TIME_LIST' ? configDataService.VEHICLE_TIME_LIST : configDataService.TIME_LIST;
        const liveMoment = moment();
        // init chartData$
        const chartData$ = isMockData ? new BehaviorSubject<GenericLineChartData[]>([]) : new BehaviorSubject<GenericLineChartData[]>(Object.entries(lineChartDataSelector).filter(([selectorName, selectedDetail], idx) => {
            if (selectorName.endsWith('DAY_OF_WEEK')) {
                const selectedDayofweek = viewPeriodService.selectedDate.isoWeekday() === 0 ? 0 : viewPeriodService.selectedDate.isoWeekday() - 1;
                lineChartDataSelector[selectorName].selected = lineChartDataSelector[selectorName].args[0] === selectedDayofweek.toLocaleString();
            }
            if (selectedDetail.selected) {
                initialColor.push(COLOR_LIST[idx % COLOR_LIST.length]);
                return true;
            }
            return false;
        }).map(([lineName, selectedDetail], index) => ({
            points: getBuildingFloorData(configDataService.isEntranceDataMode, dataBehaviorSubjects$[index].value, selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]),
            prediction: [0],
            backgroundColor: getColor(lineName).backgroundColor,
            color: getColor(lineName).color || initialColor[index],
            isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(initialTodayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
            toolTipLabel: timeLabelList.map(day => `${day}: ${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName]}`),
        })));
        const chartLabel$ = new BehaviorSubject<Label[]>(viewPeriodService.DAY_LIST);
        const busiestTimeBehaviourSubject$ = lineChartCustomLegendConfig.dataType === 'vehicle' ?
            graphDataService.vehicleParkingVehicleProfilePeakTime$ :
            graphDataService.buildingAreaBusiestTime$;

        const componentRef = viewContainerRef.createComponent(componentFactory);
        const comInstance = componentRef.instance as DynamicLineChartWrapperComponent;

        if (isMockData) {
            subscription.add(combineLatest([selectedLine$, configDataService.isEntranceDataMode$, viewPeriodService.dayList]).subscribe(async (combinedRes) => {
                const selDetails = combinedRes[0] as { [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
                const isEntrance = combinedRes[1] as boolean;
                const colors: string[] = [];
                let timeLabel: string[] = [];
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const diffDate = viewPeriodService.selectedDate.diff(todayDate, viewPeriodService.viewPeriod.toMomentCompareString());
                if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                }
                if (lineChartLebelMode !== 'DAY_LIST' && configDataService.isFeatureEnabled('time_display_name')) {
                    timeLabel = lineChartLebelMode === 'VEHICLE_TIME_LIST' ? configDataService.VEHICLE_TIME_LIST : configDataService.TIME_LIST;
                    const chartLabel = lineChartLebelMode === 'FIXED_TIME_LIST' ? timeLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time])
                        : timeLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time]).filter(time => viewPeriodService.isLiveMode ? parseInt(time, 10) <= liveMoment.hour() : true);
                    chartLabel$.next(chartLabel);
                } else {
                    timeLabel = lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST : lineChartLebelMode === 'VEHICLE_TIME_LIST' ? configDataService.VEHICLE_TIME_LIST : configDataService.TIME_LIST;
                    const chartLabel = lineChartLebelMode === 'FIXED_TIME_LIST' ? timeLabel : timeLabel.filter(time => viewPeriodService.isLiveMode && lineChartLebelMode !== 'DAY_LIST' ? parseInt(time, 10) <= liveMoment.hour() : true);
                    chartLabel$.next(chartLabel);
                }
                // const busiestTimeData = combinedRes[2];
                const mockChartDataList: any[] = [];
                const predMockChartDataList: any[] = [];
                const filteredChartValue: any[] = [];
                const filteredChartPredictedValue: any[] = [];
                const viewperiodName = viewPeriodService.viewPeriod.compareName as 'days' | 'weeks' | 'months';
                const byHour = lineChartLebelMode !== 'DAY_LIST';
                for (const [selName, selectedDetail] of Object.entries(selDetails)) {
                    const num_interval = byHour ? configDataService.TIME_LIST.length : 8;
                    const mockChartDataValue = await generateNestedData(viewPeriodService.selectedDate, viewPeriodService, configDataService, selectedDetail.name, 'traffic', num_interval, byHour);

                    if (!byHour) {
                        const predMockChartDataValue = await generateNestedData(viewPeriodService.selectedDate.add(1, viewperiodName), viewPeriodService, configDataService, selectedDetail.name, 'traffic', 2);
                        predMockChartDataList.push(predMockChartDataValue);
                    }
                    mockChartDataList.push(mockChartDataValue);
                }
                if (lineChartLebelMode !== 'DAY_LIST') {
                    const mainBuilding = configDataService.MAIN_BUILDING;
                    const entranceData = Object.values(mockChartDataList[0][mainBuilding])[0] as number[];
                    const current = entranceData.reduce((max: number, cur: number) => cur > max ? cur : max, 0);
                    const timeMapped = configDataService.TIME_LIST[entranceData.findIndex((v: any) => v === current)];
                    const timeDisplay = new Date(2020, 1, 1, parseInt(timeMapped, 10)).toLocaleTimeString('en-US', { hour12: true, hour: 'numeric' });
                    numberData$.next(current || null);
                    label$.next(timeDisplay || 'N/A');
                }
                const lineChartData: GenericLineChartData[] = Object.entries(selDetails).filter(async ([_lineName, selectedDetail], idx) => {
                    if (selectedDetail.selected) {
                        colors.push(COLOR_LIST[idx % COLOR_LIST.length]);
                        filteredChartValue.push(mockChartDataList[idx]);
                        filteredChartPredictedValue.push(predMockChartDataList[idx]);
                        return true;
                    }
                    return false;
                }).map(([lineName, selectedDetail], index) => {
                    const predRawTrafficVal = (selectedDetail.name.includes('ENTRANCE_EXIT')) ? getBuildingFloorData(isEntrance, filteredChartPredictedValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]) : null;
                    const predTrafficVal = (!(predRawTrafficVal) || diffDate < -1) ? null : ((diffDate > -1) ? predRawTrafficVal : [predRawTrafficVal[predRawTrafficVal.length - 1]]);
                    const chartPoints = getBuildingFloorData(isEntrance, filteredChartValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2])
                        .filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= moment().hour() : true);
                    const predVal = lineChartLebelMode === 'DAY_LIST' ? predTrafficVal : null;
                    return {
                        points: chartPoints,
                        prediction: predVal,
                        backgroundColor: getColor(lineName).backgroundColor,
                        color: getColor(lineName).color || colors[index],
                        isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
                        toolTipLabel: timeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName]},${day}`),
                        label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName],
                    };
                });
                chartData$.next(lineChartData);
            }));
        } else {
            subscription.add(combineLatest([selectedLine$, configDataService.isEntranceDataMode$, busiestTimeBehaviourSubject$, ...dataBehaviorSubjects$, ...dataPredictBehaviorSubjects$]).pipe(distinctUntilChanged()).subscribe((combinedRes) => {
                const selDetails = combinedRes[0] as { [selectorName: string]: { name: SelectableLineChartDataName; selected?: boolean; group?: { oper: 'SUM' | 'AVG'; data: { name: string; args: string[] }[] }; args: string[] } };
                const isEntrance = combinedRes[1] as boolean;
                const todayDate = moment().startOf(viewPeriodService.viewPeriod.toMomentString());
                const diffDate = viewPeriodService.selectedDate.diff(todayDate, viewPeriodService.viewPeriod.toMomentCompareString());
                const colors: string[] = [];
                let timeLabel: string[] = [];
                // if (chartTitleKey.endsWith('DAY_OF_WEEK')) {
                //     const updatedChartTitleKey =  'MALL_TRAFFIC_HOURLY_DAY_OF_WEEK_GROUP_DAY_OF_WEEK_DAILY';
                //     comInstance.title = viewPeriodService.isLiveMode || viewPeriodService.isDayPeriod ? configDataService.DISPLAY_LANGUAGE[updatedChartTitleKey] : configDataService.DISPLAY_LANGUAGE[chartTitleKey];
                // }
                if (isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization) && displayOnPeriodType) {
                    comInstance.isShow = viewPeriodService.isLiveMode ? displayOnPeriodType.live : displayOnPeriodType[viewPeriodService.viewPeriod.backendName];
                }
                combineLatest([graphDataService.baseGraphData.selectedDirectory$, graphDataService.baseGraphData.selectedLevel$]).subscribe(([selectedDirectory, selectedLevel]) => {
                    if (graphInfoPopoverByPeriod) {
                        const infoPopoverText = graphInfoPopoverByPeriod;
                        let infoPopoverPeriod = infoPopoverText.default;
                        if (updateTextByPeriod) {
                            if (viewPeriodService.isLiveMode) {
                                infoPopoverPeriod = infoPopoverText.default;
                            } else if (viewPeriodService.isDayPeriod) {
                                infoPopoverPeriod = infoPopoverText.day;
                            } else if (viewPeriodService.isWeekPeriod) {
                                infoPopoverPeriod = infoPopoverText.week;
                            } else if (viewPeriodService.isMonthPeriod) {
                                infoPopoverPeriod = infoPopoverText.month;
                            }
                        }
                        comInstance.infoPopover = async (e: any) => {
                            const pieChartPopover = await popoverController.create({
                                component: CustomTextTooltipsComponent,
                                componentProps: {
                                    toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                                    toolTipDetails: infoPopoverPeriod.details,
                                },
                                cssClass: 'customer-segment-details-popover',
                                event: e,
                            });
                            return await pieChartPopover.present();
                        };
                    }
                    if (excludedDirectory !== undefined) {
                        if (excludedDirectory === 'BUILDING') {
                            comInstance.isShow = selectedDirectory?.floor !== 'ALL';
                        }
                        else if (excludedDirectory === 'FLOOR' || excludedDirectory === 'ZONE') {
                            if (selectedDirectory?.floor === 'ALL' && excludedDirectory === 'FLOOR') {
                                comInstance.isShow = true;
                            } else {
                                comInstance.isShow = selectedLevel !== excludedDirectory;
                            }
                        }
                        // FLOOR_AND_ZONE, BUILDING_AND_FLOOR
                        else if (excludedDirectory.includes('AND') && excludedDirectory.indexOf('AND') > 0 && excludedDirectory.indexOf('AND') < excludedDirectory.length - 1) {
                            const excludeList = excludedDirectory.split('_AND_');
                            if (excludedDirectory === 'BUILDING_AND_FLOOR') {
                                comInstance.isShow = selectedDirectory?.floor === 'ALL' ? false : selectedLevel === 'ZONE';
                            }
                            else if (selectedDirectory?.floor === 'ALL' && excludeList.includes('FLOOR')) {
                                comInstance.isShow = true;
                            } else {
                                comInstance.isShow = !excludeList.includes(selectedLevel);
                            }
                        }
                    }
                });
                if (!combinedRes.slice(3, dataBehaviorSubjects$.length - 1).every(val => val !== null)) { return; }
                if (lineChartLebelMode !== 'DAY_LIST' && configDataService.isFeatureEnabled('time_display_name')) {
                    timeLabel = lineChartLebelMode === 'VEHICLE_TIME_LIST' ? configDataService.VEHICLE_TIME_LIST : configDataService.TIME_LIST;
                    const chartLabel = lineChartLebelMode === 'FIXED_TIME_LIST' ? timeLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time])
                        : timeLabel.map(time => configDataService.DISPLAY_LANGUAGE.TIME_NAME[time]).filter(time => viewPeriodService.isLiveMode ? parseInt(time, 10) <= liveMoment.hour() : true);
                    chartLabel$.next(chartLabel);
                } else {
                    timeLabel = lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST : lineChartLebelMode === 'VEHICLE_TIME_LIST' ? configDataService.VEHICLE_TIME_LIST : configDataService.TIME_LIST;
                    const chartLabel = lineChartLebelMode === 'FIXED_TIME_LIST' ? timeLabel : timeLabel.filter(time => viewPeriodService.isLiveMode && lineChartLebelMode !== 'DAY_LIST' ? parseInt(time, 10) <= liveMoment.hour() : true);
                    chartLabel$.next(chartLabel);
                }
                const busiestTimeData = combinedRes[2];
                const chartValue = combinedRes.slice(3, dataBehaviorSubjects$.length + 3);
                const chartPredictValue = combinedRes.slice(dataBehaviorSubjects$.length + 3);
                const filteredChartValue: any[] = [];
                const filteredChartPredictedValue: any[] = [];
                if (filteredGroupData) {
                    const filteredKey = Object.entries(selDetails).filter(([_lineName, selectedDetail], idx) => {
                        if (selectedDetail.selected) {
                            colors.push(COLOR_LIST[idx % COLOR_LIST.length]);
                            filteredChartValue.push(chartValue[idx] as number[]);
                            filteredChartPredictedValue.push(chartPredictValue[idx] as number[]);
                            return true;
                        }
                        return false;
                    });
                    const dataObject = getDepthData(true, filteredChartValue[0], { buildingName: filteredKey[0][1].args[0] });
                    const defaultLineChartData: GenericLineChartData[] = [
                        {
                            points: [],
                            label: ''
                        }
                    ];
                    if (!dataObject) {
                        chartData$.next(defaultLineChartData);
                    } else {
                        let lineChartData: GenericLineChartData[];
                        if (compareGraph) {
                            const numberDate = viewPeriodService.selectedDate.day();
                            const filteredObj = Object.keys(dataObject)
                                .filter(key => key.endsWith(`${numberDate}`))
                                .reduce((result, key) => {
                                    result[key] = dataObject[key];
                                    return result;
                                }, {});
                            lineChartData = Object.entries(filteredObj).map(([dataKey, dataValue], index) => ({
                                points: isEntrance
                                    ? ((dataValue as { entrance: number[] }).entrance as number[]).filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= liveMoment.hour() : true)
                                    : ((dataValue as { exit: number[] }).exit as number[]).filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= liveMoment.hour() : true),
                                backgroundColor: getColor(dataKey).backgroundColor,
                                color: getColor(dataKey).color || colors[index],
                                isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
                                toolTipLabel: timeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[dataKey] || dataKey},${day}`),
                                label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[dataKey] || dataKey,
                                isDash: dataKey.startsWith('dayweek') ? false : true,
                            }));
                        } else {
                            lineChartData = Object.entries(dataObject).map(([dataKey, dataValue], index) => ({
                                points: isEntrance
                                    ? (dataValue.entrance as number[]).filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= liveMoment.hour() : true)
                                    : (dataValue.exit as number[]).filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= liveMoment.hour() : true),
                                backgroundColor: getColor(dataKey).backgroundColor,
                                color: getColor(dataKey).color || colors[index],
                                isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
                                toolTipLabel: timeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[dataKey] || dataKey},${day}`),
                                label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[dataKey] || dataKey,
                            }));
                        }
                        chartData$.next(lineChartData);
                    }
                } else {
                    const lineChartData: GenericLineChartData[] = Object.entries(selDetails).filter(([_lineName, selectedDetail], idx) => {
                        if (selectedDetail.selected) {
                            colors.push(COLOR_LIST[idx % COLOR_LIST.length]);
                            filteredChartValue.push(chartValue[idx] as number[]);
                            filteredChartPredictedValue.push(chartPredictValue[idx] as number[]);
                            return true;
                        }
                        return false;
                    }).map(([lineName, selectedDetail], index) => {
                        const arr2DOfDataPoints: number[][] = [];
                        const arr2DOfPredPoints: number[][] = [];
                        if (selectedDetail?.group) {
                            const graphDataGroup = selectedDetail.group.data;
                            for (const graphData of graphDataGroup) {
                                const dataPoint = getBuildingFloorData(isEntrance, filteredChartValue[index], graphData.args[0], graphData.args[1], graphData.args[2]);
                                const predRawTrafficDataGroup = (selectedDetail.name.includes('ENTRANCE_EXIT')) ? getBuildingFloorData(isEntrance, filteredChartPredictedValue[index], graphData.args[0], graphData.args[1], graphData.args[2]) : null;
                                const predTrafficGroupVal = (!(predRawTrafficDataGroup) || diffDate < -1) ? null : ((diffDate > -1) ? predRawTrafficDataGroup : [predRawTrafficDataGroup[predRawTrafficDataGroup.length - 1]]);
                                const predPoint = chartTitleKey.includes('TRAFFIC') ? predTrafficGroupVal : null;
                                arr2DOfDataPoints.push(dataPoint);
                                arr2DOfPredPoints.push(predPoint);
                            }
                            const dataPoints = selectedDetail.group.oper === 'AVG' ? arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? (a[i] + b) / a.length - 1 : null)) : arr2DOfDataPoints.reduce((r, a) => r.map((b, i) => b !== null ? a[i] + b : null));
                            const dataPredPoints = arr2DOfPredPoints.every(arr => arr === null) ? null :
                                selectedDetail.group.oper === 'AVG' ? arr2DOfPredPoints.reduce((r, a) => r.every(d => d === null) ? null : r.map((b, i) => b !== null ? (a[i] + b) / a.length - 1 : null)) : arr2DOfPredPoints.reduce((r, a) => r.map((b, i) => b !== null ? a[i] + b : null));
                            return {
                                points: dataPoints,
                                prediction: dataPredPoints,
                                backgroundColor: getColor(lineName).backgroundColor,
                                color: getColor(lineName).color || colors[index],
                                isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
                                toolTipLabel: timeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName]},${day}`),
                                label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName],
                            };
                        } else {
                            const predRawTrafficVal = (selectedDetail.name.includes('ENTRANCE_EXIT')) ? getBuildingFloorData(isEntrance, filteredChartPredictedValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]) : null;
                            const predTrafficVal = (!(predRawTrafficVal) || diffDate < -1) ? null : ((diffDate > -1) ? predRawTrafficVal : [predRawTrafficVal?.[predRawTrafficVal.length - 1] ?? 0]);
                            const test = getBuildingFloorData(isEntrance, filteredChartValue[index], selectedDetail.args[0], selectedDetail.args[1], selectedDetail.args[2]);
                            const chartPoints = test.filter((d, i) => viewPeriodService.isLiveMode && (lineChartLebelMode === 'TIME_LIST' || lineChartLebelMode === 'VEHICLE_TIME_LIST') ? parseInt(timeLabel[i], 10) <= moment().hour() : true);
                            const predVal = chartTitleKey.includes('TRAFFIC') ? configDataService.isFeatureEnabled('mock_data', 'building_entrance_exit') ? [chartPoints[chartPoints.length - 1]] : predTrafficVal : null;
                            return {
                                points: chartPoints,
                                prediction: predVal,
                                backgroundColor: getColor(lineName).backgroundColor,
                                color: getColor(lineName).color || colors[index],
                                isLivePeriod: lineChartLebelMode === 'DAY_LIST' ? viewPeriodService.DAY_LIST_MOMENT.map(dateMoment => dateMoment.clone().isSameOrAfter(todayDate.clone(), viewPeriodService.viewPeriod.toMomentCompareString())) : undefined,
                                toolTipLabel: timeLabel.map(day => `${configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName]},${day}`),
                                label: configDataService.DISPLAY_LANGUAGE.DYNAMIC_SELECTOR_NAME[lineName],
                            };

                        }
                    });
                    chartData$.next(lineChartData);
                }
                if (configDataService.currentOrganization === 'TALAAD') {
                    const buiestData = busiestTimeData as { timeKey: string; count: number };
                    numberData$.next(buiestData?.count || null);
                    label$.next(buiestData?.timeKey || 'N/A');
                } else {
                    numberData$.next(busiestTimeData?.[lineChartCustomLegendConfig?.areaName || configDataService.MAIN_BUILDING]?.headcount || null);
                    label$.next(busiestTimeData?.[lineChartCustomLegendConfig?.areaName || configDataService.MAIN_BUILDING]?.hour || 'N/A');
                }
            }));
        }

        comInstance.isShow = isShowGraph(useOnSpecificUser, authenicationService.userProfile, configDataService.SPECTIFIC_UID, onSpecificOrganization);
        comInstance.data$ = chartData$;
        comInstance.isShowingLegend = true;
        comInstance.label$ = chartLabel$;
        comInstance.selectInterface = 'alert';
        comInstance.selector = lineChartDataSelector;
        comInstance.selectedLine$ = selectedLine$;
        comInstance.title = configDataService.DISPLAY_LANGUAGE[chartTitleKey];
        comInstance.isShowOnPeakTime = lineChartCustomLegendConfig.showType === 'PeakTime';
        comInstance.isShowCustomLegend = lineChartCustomLegendConfig.isShow;
        comInstance.labelIcon = labelIcon;
        comInstance.labelStyle = labelStyle;
        comInstance.numberData$ = numberData$;
        comInstance.labelCustomLegend$ = label$;
        comInstance.labelHeader$ = labelHeader$;
        if (filteredMultiple !== undefined) {
            comInstance.multipleSelector = filteredMultiple;
        }
        comInstance.showAveragePerDay = showAveragePerDay;
        if (chartTitleKey.endsWith('DAY_OF_WEEK')) {
            comInstance.infoPopover = async (e: any) => {
                const popover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: [],
                        showDurationText: true,
                        customTooltipText
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await popover.present();
            };
        }
        if (chartTitleKey.startsWith('OVERALL_POPULAR_TIME')) {
            comInstance.infoPopover = async (e: any) => {
                const overAllPopularTimePopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: configDataService.isFeatureEnabled('mall_traffic_overview', 'busiest_time_by_entrance') ?
                            ['Based on entrance visitors at each hour.'] :
                            ['Based on accumulated visitors at each hour.', 'Calculated by "cumulative entrance" - "cumulative exit" at each hour.'],
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await overAllPopularTimePopover.present();
            };
        }
        if (graphInfoPopoverByPeriod) {
            const infoPopoverText = graphInfoPopoverByPeriod;
            let infoPopoverPeriod = infoPopoverText.default;
            if (updateTextByPeriod) {
                if (viewPeriodService.isLiveMode) {
                    infoPopoverPeriod = infoPopoverText.default;
                } else if (viewPeriodService.isDayPeriod) {
                    infoPopoverPeriod = infoPopoverText.day;
                } else if (viewPeriodService.isWeekPeriod) {
                    infoPopoverPeriod = infoPopoverText.week;
                } else if (viewPeriodService.isMonthPeriod) {
                    infoPopoverPeriod = infoPopoverText.month;
                }
            }
            comInstance.infoPopover = async (e: any) => {
                const pieChartPopover = await popoverController.create({
                    component: CustomTextTooltipsComponent,
                    componentProps: {
                        toolTipTitle: infoPopoverPeriod.title || configDataService.DISPLAY_LANGUAGE[chartTitleKey],
                        toolTipDetails: infoPopoverPeriod.details,
                    },
                    cssClass: 'customer-segment-details-popover',
                    event: e,
                });
                return await pieChartPopover.present();
            };
        }
        comInstance.isLock = graphState === 'LOCK';
        return comInstance;
    }

}
